import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { useOptionChainContext } from "../context/OptionChainContext";
import { getSymbols } from "../utils/api";
import ExpiryDates from "../components/ExpiryDates";
import { ExpiryDatesProvider } from "../context/ExpiryDatesContext";
import axios from "axios";
import OptionChainTable from "../components/OptionChainTable";
import { useLtpContext } from "../context/LtpContext";
import ABWebSocketContext from "../context/ABWebsocketContext";
import WebsocketWrapperContext from "../context/WebsocketWrapperContext";
import { useTheme } from "../context/ThemeContext";
import { useDataContext } from "../context/DataContext";

const OptionChainMobile = ({
  parentCallback,
  parentCallback2,
  initialOptionData,
  selectedSymbol,
  selectedData,
  setData,
}) => {
  const { theme } = useTheme();
  const {
    updateSymbolList,
    websocketConnection,
    checkWebsocketConnection,
    subscribeToSymbols,
    unsubscribeToSymbols,
    symbolList,
    symbolData,
  } = useContext(WebsocketWrapperContext);
  // const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [activeDate, setActiveDate] = useState("");
  const { optionData, setOptionData } = useOptionChainContext();
  const [tempOptionData, setTempOptionData] = useState({});
  const { reducedData, setReducedData } = useState();
  const [allTradingSymbols, setAllTradingSymbols] = useState({});
  const [calls, setCalls] = useState();
  const [puts, setPuts] = useState();
  const { callsDataCon, setCallsDataCon, putsDataCon, setPutsDataCon } =
    useDataContext();
  var iv = require("implied-volatility");
  var greeks = require("greeks");

  // For dates scroll
  const [currentPage, setCurrentPage] = useState(1);
  const datesPerPage = 2;
  const totalPages =
    selectedSymbol && selectedSymbol.option_expiries
      ? Math.ceil(selectedSymbol.option_expiries.length / datesPerPage)
      : 0;
  // console.log("Expiries", selectedSymbol.option_expiries);
  const indexOfLastDate = currentPage * datesPerPage;
  const indexOfFirstDate = indexOfLastDate - datesPerPage;
  const showPrevious = currentPage > 1;
  const showNext = currentPage < totalPages;

  const handleNextPage = () => {
    if (showNext) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (showPrevious) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleLotSizeChange = (newValue, index, side) => {
    const newLotsSize = parseInt(newValue); // Convert the new value to an integer
    if (
      selectedData.length > 0 &&
      selectedData.some(
        (position) =>
          position.tradingSymbol ===
          (side === "calls"
            ? callsDataCon[index].trading_symbol
            : putsDataCon[index].trading_symbol)
      )
    ) {
      const checkTradingSymbol =
        side === "calls"
          ? callsDataCon[index].trading_symbol
          : putsDataCon[index].trading_symbol;

      const positionIndex = selectedData.findIndex(
        (position) => position.tradingSymbol === checkTradingSymbol
      );
      if (positionIndex !== -1) {
        // Position found in selectedData
        const updatedBasketData = [...selectedData];
        updatedBasketData[positionIndex].lotSize = newLotsSize;
        if (side === "calls") {
          callsDataCon[index].LotsSize = newLotsSize;
        } else {
          putsDataCon[index].LotsSize = newLotsSize;
        }
        setData(updatedBasketData);
      }
    } else {
      // Basket is empty, update options data
      if (side === "calls") {
        const updatedCallsDataCon = [...callsDataCon];
        updatedCallsDataCon[index].LotsSize = newLotsSize;
        setCallsDataCon(updatedCallsDataCon);
      } else {
        const updatedPutsDataCon = [...putsDataCon];
        updatedPutsDataCon[index].LotsSize = newLotsSize;
        setPutsDataCon(updatedPutsDataCon);
      }
    }
  };

  //function to handle lots size by clicking on increment
  const incrementValue = (index, side) => {
    if (
      selectedData.length > 0 &&
      selectedData.some(
        (position) =>
          position.tradingSymbol ===
          (side === "calls"
            ? callsDataCon[index].trading_symbol
            : putsDataCon[index].trading_symbol)
      )
    ) {
      const checkTradingSymbol =
        side === "calls"
          ? callsDataCon[index].trading_symbol
          : putsDataCon[index].trading_symbol;

      for (let i = 0; i < selectedData.length; i++) {
        if (
          selectedData[i].tradingSymbol === checkTradingSymbol &&
          side === "calls"
        ) {
          let dataObject = selectedData[i];
          dataObject["lotSize"] = dataObject["lotSize"] + 1;
          callsDataCon[index].LotsSize += 1;
          setData((prevData) => [...prevData]);
        }
        if (
          selectedData[i].tradingSymbol === checkTradingSymbol &&
          side === "puts"
        ) {
          let dataObject = selectedData[i];
          dataObject["lotSize"] = dataObject["lotSize"] + 1;
          putsDataCon[index].LotsSize += 1;
          setData((prevData) => [...prevData]);
        }
      }
    } else {
      if (side === "calls") {
        const updatedCallsDataCon = [...callsDataCon];
        updatedCallsDataCon[index].LotsSize += 1;
        setCallsDataCon(updatedCallsDataCon);
      } else {
        const updatedPutsDataCon = [...putsDataCon];
        updatedPutsDataCon[index].LotsSize += 1;
        setPutsDataCon(updatedPutsDataCon);
      }
    }
  };

  //function to handle lots size by clicking on decrement
  const decrementValue = (index, side) => {
    if (
      selectedData.length > 0 &&
      selectedData.some(
        (position) =>
          position.tradingSymbol ===
          (side === "calls"
            ? callsDataCon[index].trading_symbol
            : putsDataCon[index].trading_symbol)
      )
    ) {
      const checkTradingSymbol =
        side === "calls"
          ? callsDataCon[index].trading_symbol
          : putsDataCon[index].trading_symbol;
      for (let i = 0; i < selectedData.length; i++) {
        if (
          selectedData[i].tradingSymbol === checkTradingSymbol &&
          selectedData[i].lotSize > 1
        ) {
          let dataObject = selectedData[i];
          dataObject["lotSize"] = dataObject["lotSize"] - 1;
          if (side === "calls") {
            callsDataCon[index].LotsSize -= 1;
          } else {
            putsDataCon[index].LotsSize -= 1;
          }
          setData((prevData) => [...prevData]);
        }
      }
    } else {
      if (side === "calls") {
        if (callsDataCon[index].LotsSize > 1) {
          const updatedCallsDataCon = [...callsDataCon];
          updatedCallsDataCon[index].LotsSize -= 1;
          setCallsDataCon(updatedCallsDataCon);
        }
      } else {
        if (putsDataCon[index].LotsSize > 1) {
          const updatedPutsDataCon = [...putsDataCon];
          updatedPutsDataCon[index].LotsSize -= 1;
          setPutsDataCon(updatedPutsDataCon);
        }
      }
    }
  };

  useLayoutEffect(() => {
    const storedSymbol = sessionStorage.getItem("selectedSymbol");
    if (storedSymbol) {
      // setSelectedSymbol(JSON.parse(storedSymbol));
    } else {
      const fetchSymbols = async () => {
        const symbols = await getSymbols();
        if (symbols) {
          const niftySymbol = symbols.find(
            (symbol) => symbol.scrip === "NIFTY"
          );
          // setSelectedSymbol(niftySymbol);
          sessionStorage.setItem("selectedSymbol", JSON.stringify(niftySymbol));
        }
      };

      fetchSymbols();
    }
  }, []);

  //This code is to keep the option chain and bakset of strategies in sync
  if (selectedData.length >= 1) {
    for (let j = 0; j < callsDataCon.length; j++) {
      let matching_flag = false;
      for (let i = 0; i < selectedData.length; i++) {
        const checkTradingSymbol = selectedData[i].tradingSymbol;
        const direction = selectedData[i].isBuy ? "B" : "S";
        const side = selectedData[i].isCall ? "Put" : "Call";
        if (side === "Call") {
          if (callsDataCon[j].trading_symbol === checkTradingSymbol) {
            matching_flag = true;
            callsDataCon[j].LotsSize = selectedData[i].lotSize;
            if (direction === "B") {
              callsDataCon[j].buyBtnState = true;
              callsDataCon[j].sellBtnState = false;
            } else {
              callsDataCon[j].sellBtnState = true;
              callsDataCon[j].buyBtnState = false;
            }
          }
        }
      }
      if (!matching_flag) {
        callsDataCon[j].buyBtnState = false;
        callsDataCon[j].sellBtnState = false;
      }
    }

    for (let j = 0; j < putsDataCon.length; j++) {
      let matching_flag = false;
      for (let i = 0; i < selectedData.length; i++) {
        const checkTradingSymbol = selectedData[i].tradingSymbol;
        const direction = selectedData[i].isBuy ? "B" : "S";
        const side = selectedData[i].isCall ? "Call" : "Put";
        if (side === "Put") {
          if (putsDataCon[j].trading_symbol === checkTradingSymbol) {
            matching_flag = true;
            putsDataCon[j].LotsSize = selectedData[i].lotSize;
            if (direction === "B") {
              putsDataCon[j].buyBtnState = true;
              putsDataCon[j].sellBtnState = false;
            } else {
              putsDataCon[j].sellBtnState = true;
              putsDataCon[j].buyBtnState = false;
            }
          }
        }
      }
      if (!matching_flag) {
        putsDataCon[j].buyBtnState = false;
        putsDataCon[j].sellBtnState = false;
      }
    }
  }

  const checkIfPresentInBasket = (side, selectedOption, basketData) => {
    // console.log("this is data ->",basketData)
    return basketData.some((position) => {
      const p = position.isCall;
      const temp =
        side === p
          ? selectedOption.put.trading_symbol
          : selectedOption.call.trading_symbol;
      const checker = position.isCall ? "puts" : "calls";

      return checker == side && position.tradingSymbol == temp;
    });
  };

  const updateBasket = (trading_symbol, isBuy) => {
    setData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item.tradingSymbol === trading_symbol) {
          if (item.isBuy === isBuy) {
            return null;
          } else {
            return { ...item, isBuy: isBuy };
          }
        }
        return item;
      });
      // setBtnColor({ updatedData });
      // Remove null entries (items to be deleted)
      return updatedData.filter((item) => item !== null);
    });
  };

  useEffect(() => {
    if (optionData && optionData.options && optionData.options.length > 0) {
      let callsTempData = optionData.options?.map((option) => ({
        ...option.call,
        buyBtnState: false,
        sellBtnState: false,
        LotsSize: 1,
      }));

      let putsTempData = optionData.options?.map((option) => ({
        ...option.put,
        buyBtnState: false,
        sellBtnState: false,
        LotsSize: 1,
      }));
      setCallsDataCon(callsTempData);
      setPutsDataCon(putsTempData);
    }
  }, [optionData]);

  const handleSymbolSelection = async (symbol, date) => {
    const formattedDate = date.replaceAll(" ", "%20");
    setSelectedDate(formattedDate);
    setActiveDate(date);
    await fetchDataForSymbol(symbol.scrip, formattedDate);
  };

  useLayoutEffect(() => {
    if (
      Object.keys(tempOptionData).length > 0 &&
      symbolData[selectedSymbol?.code]
    ) {
      let underlying_ltp = symbolData[selectedSymbol?.code]?.ltp;
      let data = tempOptionData;
      const formattedData = data["options"].map((item) => ({
        ...item,
        strike: parseInt(item.strike),
      }));

      let minDiff = Math.abs(
        formattedData[0].strike - parseFloat(underlying_ltp)
      );
      let closestIdx = 0;

      // Iterate through the array to find the closest index
      formattedData.forEach((item, index) => {
        const diff = Math.abs(item.strike - parseFloat(underlying_ltp));
        if (diff < minDiff) {
          minDiff = diff;
          closestIdx = index;
        }
      });
      const selected = formattedData.slice(
        Math.max(0, closestIdx - 20),
        Math.min(formattedData.length, closestIdx + 20)
      );
      data["options"] = selected;
      setOptionData(data);

      parentCallback2(data);

      const call = getCodeTradingSymbolMappings(selected, "call");
      const put = getCodeTradingSymbolMappings(selected, "put");

      const tradingSymbols = call.concat(put);
      setAllTradingSymbols(tradingSymbols);
      setTempOptionData({});
      subscribeToSymbols(tradingSymbols, data?.options[0]?.call.exchange);
    }
  }, [tempOptionData, symbolData]);

  const getCodeTradingSymbolMappings = (data, key) => {
    const codeTradingSymbol = [];
    for (const item of data) {
      const { code } = item[key];
      codeTradingSymbol.push(code);
    }
    return codeTradingSymbol;
  };

  const fetchDataForSymbol = async (symbol, date) => {
    let brokerName = localStorage.getItem("broker");

    if (!brokerName) {
      console.error("Broker name not found in local storage.");
      return;
    }

    brokerName = brokerName.toLowerCase().replace(/\s+/g, "");
    const apiEndpoint = `https://y18wfq6w5b.execute-api.ap-south-1.amazonaws.com/default/og_expirysymbols`;
    try {
      if (Object.keys(tempOptionData).length === 0) {
        const response = await axios.post(apiEndpoint, {
          id: symbol + date,
          broker: brokerName,
        });

        const data = response.data;

        setTempOptionData(data);
        if (Object.keys(allTradingSymbols).length > 0) {
          unsubscribeToSymbols(
            allTradingSymbols,
            data?.options[0]?.call.exchange
          );
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useLayoutEffect(() => {
    const fetchDefaultData = () => {
      if (
        selectedSymbol &&
        selectedSymbol.option_expiries &&
        selectedSymbol.option_expiries.length > 0
      ) {
        const defaultDate = selectedSymbol.option_expiries[0]; // Selecting the first date
        const formattedDate = defaultDate.replaceAll(" ", "%20");
        setActiveDate(defaultDate);
        if (optionData) {
          if (selectedSymbol.scrip + defaultDate !== optionData["id"]) {
            fetchDataForSymbol(selectedSymbol.scrip, formattedDate);
          }
        } else {
          fetchDataForSymbol(selectedSymbol.scrip, formattedDate);
        }
      }
    };
    fetchDefaultData();
  }, [selectedSymbol]);

  useLayoutEffect(() => {
    computeGreeks();
  }, [symbolData, optionData]);

  const computeGreeks = () => {
    //using parsefloat only once so we dont need to use multiple times
    const ltp = parseFloat(symbolData[selectedSymbol?.code]?.ltp);
    const riskFreeRate = 0.1 / 365; //moved this out of the loop

    // using forrach instead of map
    optionData?.options.forEach((data) => {
      const call_option = data.call;
      const put_option = data.put;
      const strike = data.strike;

      const isCallOption = strike > ltp;
      const optionType = isCallOption ? "call" : "put";
      const relevantOption = isCallOption ? call_option : put_option;

      const targetDateTimeString = put_option.expiry_date + " 15:29:59"; // Target date and time
      const targetDateTime = new Date(targetDateTimeString);
      const currentDate = new Date();
      let timeToExpiration = Math.max(
        0,
        (targetDateTime - currentDate) / (24 * 60 * 60 * 365 * 1000) ||
          0.0002 / 365
      );

      const currentStockPrice = parseFloat(
        symbolData[relevantOption?.code]?.ltp
      );

      if (!call_option.iv) {
        //instead of repeting same calculations for calls and puts, combining them here
        const initialGuessIv = iv.getImpliedVolatility(
          currentStockPrice,
          ltp,
          strike,
          timeToExpiration,
          riskFreeRate,
          optionType
        );
        if (initialGuessIv <= 1) {
          const ivValue = (initialGuessIv * 100).toFixed(2);
          const deltaValue = (
            greeks.getDelta(
              ltp,
              strike,
              timeToExpiration,
              initialGuessIv,
              riskFreeRate,
              "call"
            ) * 100
          ).toFixed(2);
          const thetaValue = (
            greeks.getTheta(
              ltp,
              strike,
              timeToExpiration,
              initialGuessIv,
              riskFreeRate,
              "call"
            ) * 100
          ).toFixed(2);
          const gammaValue = (
            greeks.getGamma(
              ltp,
              strike,
              timeToExpiration,
              initialGuessIv,
              riskFreeRate,
              "call"
            ) * 100
          ).toFixed(2);
          const vegaValue = (
            greeks.getVega(
              ltp,
              strike,
              timeToExpiration,
              initialGuessIv,
              riskFreeRate,
              "call"
            ) * 100
          ).toFixed(2);
          data.call.iv = ivValue;
          data.call.delta = deltaValue;
          data.call.theta = thetaValue;
          data.call.gamma = gammaValue;
          data.call.vega = vegaValue;
        }
      }
      if (!put_option.iv) {
        const initialGuessIv = iv.getImpliedVolatility(
          currentStockPrice,
          ltp,
          strike,
          timeToExpiration,
          riskFreeRate,
          optionType
        );
        if (initialGuessIv <= 1) {
          const ivValue = (initialGuessIv * 100).toFixed(2);
          const deltaValue = (
            greeks.getDelta(
              ltp,
              strike,
              timeToExpiration,
              initialGuessIv,
              riskFreeRate,
              "put"
            ) * 100
          ).toFixed(2);
          const thetaValue = (
            greeks.getTheta(
              ltp,
              strike,
              timeToExpiration,
              initialGuessIv,
              riskFreeRate,
              "put"
            ) * 100
          ).toFixed(2);
          const gammaValue = (
            greeks.getGamma(
              ltp,
              strike,
              timeToExpiration,
              initialGuessIv,
              riskFreeRate,
              "put"
            ) * 100
          ).toFixed(2);
          const vegaValue = (
            greeks.getVega(
              ltp,
              strike,
              timeToExpiration,
              initialGuessIv,
              riskFreeRate,
              "put"
            ) * 100
          ).toFixed(2);
          data.put.iv = ivValue;
          data.put.delta = deltaValue;
          data.put.theta = thetaValue;
          data.put.gamma = gammaValue;
          data.put.vega = vegaValue;
        }
      }
    });
  };

  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedSide, setSelectedSide] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState({ top: 0, left: 0 });
  const getReducedData = () => {
    const sample = optionData && optionData.options && optionData.options;
    if (sample) {
      let f = sample.reduce((groupedOptions, option) => {
        const strike = option.call.strike;
        if (!groupedOptions[strike]) {
          groupedOptions[strike] = {
            call: option.call,
            put: option.put,
          };
        } else {
          groupedOptions[strike].call = {
            ...groupedOptions[strike].call,
            ...option.call,
          };
          groupedOptions[strike].put = {
            ...groupedOptions[strike].put,
            ...option.put,
          };
        }
        return groupedOptions;
      }, []);
      return f;
    }
  };

  const [divVisibility, setDivVisibility] = useState(false);
  const handleRowClick = (event, rowIndex, side) => {
    setDivVisibility(true);
    const rowRect = event.currentTarget.getBoundingClientRect();
    const top = rowRect.top + window.scrollY;
    const left = rowRect.left + window.scrollX;
    setSelectedSide(side);
    setSelectedRowIndex(rowIndex);

    // Setting the selected row index and side in state variables
    setSelectedRowIndex(rowIndex);
    setSelectedSide(side);

    // Setting the position of the clicked row
    setSelectedPosition({ top, left });
  };

  const containerRef = useRef(null);

  // Refs to store the latest
  const selectedRowIndexRef = useRef(null);
  const selectedSideRef = useRef(null);
  const optionDataRef = useRef(null);

  // const handleButtonClick = (event) => {
  //   const target = event.target;
  //   const isBuyButton = target.classList.contains("buy-btn");
  //   const isSellButton = target.classList.contains("sell-btn");
  //   if (isBuyButton) {
  //     setSelectedRowIndex(selectedRowIndexRef.current);
  //     setSelectedSide(selectedSideRef.current);
  //     handleClick("Buy");
  //   } else if (isSellButton) {
  //     setSelectedRowIndex(selectedRowIndexRef.current);
  //     setSelectedSide(selectedSideRef.current);
  //     handleClick("Sell");
  //   }
  // };

  //This block of container is used because in a way we have two onclick elements at a single place
  // useEffect(() => {
  //   const container = containerRef.current;
  //   container.addEventListener("click", handleButtonClick);
  //   return () => {
  //     container.removeEventListener("click", handleButtonClick);
  //   };
  // }, []);

  useEffect(() => {
    selectedRowIndexRef.current = selectedRowIndex;
    selectedSideRef.current = selectedSide;
    optionDataRef.current = optionData;
  }, [selectedRowIndex, selectedSide, optionData]);

  // useEffect(() => {
  //   if (selectedSymbol && selectedDate) {
  //     const f = getReducedData();
  //     setReducedData(f);
  //   }
  // }, [selectedSymbol, selectedDate]);

  const handleClick = (buy_sell_type, lotsProp) => {
    if (callsDataCon && selectedSide === "calls") {
      const updatedCallsDataCon = [...callsDataCon];
      if (updatedCallsDataCon[selectedRowIndex] && buy_sell_type === "Buy") {
        updatedCallsDataCon[selectedRowIndex].buyBtnState =
          !updatedCallsDataCon[selectedRowIndex].buyBtnState;
        setCallsDataCon(updatedCallsDataCon);
        if (updatedCallsDataCon[selectedRowIndex].sellBtnState) {
          updatedCallsDataCon[selectedRowIndex].sellBtnState = false;
        }
      }
      if (updatedCallsDataCon[selectedRowIndex] && buy_sell_type === "Sell") {
        updatedCallsDataCon[selectedRowIndex].sellBtnState =
          !updatedCallsDataCon[selectedRowIndex].sellBtnState;
        setCallsDataCon(updatedCallsDataCon);
        if (updatedCallsDataCon[selectedRowIndex].buyBtnState) {
          updatedCallsDataCon[selectedRowIndex].buyBtnState = false;
        }
      } else {
        console.error(`Error: callsDataCon[${selectedRowIndex}] is undefined`);
      }
    } else {
      console.error("Error: callsDataCon is undefined");
    }

    if (putsDataCon && selectedSide === "puts") {
      const updatedPutsDataCon = [...putsDataCon];
      if (updatedPutsDataCon[selectedRowIndex] && buy_sell_type === "Buy") {
        updatedPutsDataCon[selectedRowIndex].buyBtnState =
          !updatedPutsDataCon[selectedRowIndex].buyBtnState;
        setPutsDataCon(updatedPutsDataCon);
        if (updatedPutsDataCon[selectedRowIndex].sellBtnState) {
          updatedPutsDataCon[selectedRowIndex].sellBtnState = false;
        }
      }
      if (updatedPutsDataCon[selectedRowIndex] && buy_sell_type === "Sell") {
        updatedPutsDataCon[selectedRowIndex].sellBtnState =
          !updatedPutsDataCon[selectedRowIndex].sellBtnState;
        setPutsDataCon(updatedPutsDataCon);
        if (updatedPutsDataCon[selectedRowIndex].buyBtnState) {
          updatedPutsDataCon[selectedRowIndex].buyBtnState = false;
        }
      } else {
        console.error(`Error: putsDataCon[${selectedRowIndex}] is undefined`);
      }
    } else {
      console.error("Error: putsDataCon is undefined");
    }
    const f = getReducedData();
    const selectedStrike = uniqueStrikes[selectedRowIndex];
    const selectedOption = f[selectedStrike];
    const existingPositionIndex = checkIfPresentInBasket(
      selectedSide,
      selectedOption,
      selectedData
    );
    if (
      selectedSymbol &&
      optionData &&
      optionData.options &&
      selectedRowIndex !== null &&
      selectedSide !== null
    ) {
      const selectedStrike = uniqueStrikes[selectedRowIndex];
      const selectedOption = f[selectedStrike];
      const side = selectedSide === "calls" ? "Call" : "Put";
      const instrumentName =
        side === "Call"
          ? selectedOption.call.instrument_name
          : selectedOption.put.instrument_name;
      const lotSize =
        side === "Call"
          ? selectedOption.call.lot_size
          : selectedOption.put.lot_size;
      const quantity = lotsProp;
      const optionType = side === "Call" ? "CE" : "PE";
      const tradingSymbol =
        side === "Call"
          ? selectedOption.call.trading_symbol
          : selectedOption.put.trading_symbol;
      const expiryDate =
        side === "Call"
          ? selectedOption.call.expiry_date
          : selectedOption.put.expiry_date;
      const code =
        side === "Call" ? selectedOption.call.code : selectedOption.put.code;

      const buy_sell = buy_sell_type;
      const price =
        selectedSide === "calls"
          ? symbolData[selectedOption.call.code]?.ltp || 0
          : symbolData[selectedOption.put.code]?.ltp || 0;
      let freeze_qty =
        side === "Call"
          ? selectedOption.call.freeze_qty
          : selectedOption.put.freeze_qty;
      let exchange =
        side === "Call"
          ? selectedOption.call.exchange
          : selectedOption.put.exchange;

      const strike =
        side === "Call"
          ? selectedOption.call.strike
          : selectedOption.put.strike;

      let lot_multiplier = parseFloat(optionData?.lot_size);
      const dataObject = {
        side,
        instrumentName,
        lotSize,
        quantity,
        optionType,
        tradingSymbol,
        expiryDate,
        code,
        buy_sell,
        code,
        freeze_qty: freeze_qty,
        exchange: exchange,
        lot_multiplier: lot_multiplier,
        scrip: selectedSymbol.scrip,
        strike,
        price,
      };

      if (selectedData.length > 0) {
        if (existingPositionIndex) {
          updateBasket(tradingSymbol, buy_sell === "Buy" ? true : false);
        } else {
          parentCallback([[dataObject]], "add");
        }
      } else {
        parentCallback([[dataObject]], "add");
      }
    }
  };

  const handleSellClick = () => {
    const f = getReducedData();

    if (
      selectedSymbol &&
      optionData &&
      optionData.options &&
      selectedRowIndex !== null &&
      selectedSide !== null
    ) {
      const selectedOption = f[selectedRowIndex];
      const side = selectedSide === "calls" ? "Call" : "Put";
      const instrumentName =
        side === "Call"
          ? selectedOption.call.instrument_name
          : selectedOption.put.instrument_name;
      const lotSize =
        side === "Call"
          ? selectedOption.call.lot_size
          : selectedOption.put.lot_size;
      const optionType = side === "Call" ? "CE" : "PE";
      const tradingSymbol =
        side === "Call"
          ? selectedOption.call.trading_symbol
          : selectedOption.put.trading_symbol;
      const expiryDate = selectedDate;
      const code =
        side === "Call" ? selectedOption.call.code : selectedOption.put.code;
      const dataObject = {
        side,
        instrumentName,
        lotSize,
        optionType,
        tradingSymbol,
        expiryDate,
        code,
      };

      parentCallback([dataObject, "S"]);
      setDivVisibility(false);
    }
  };

  let uniqueStrikes = Array.from(
    new Set(
      optionData?.options
        ? optionData?.options?.map((option) => option.call.strike)
        : null
    )
  );
  uniqueStrikes.sort((a, b) => a - b);
  if (uniqueStrikes.length == 0) {
    setTimeout(() => {
      uniqueStrikes = Array.from(
        new Set(
          optionData?.options
            ? optionData?.options.map((option) => option.call.strike)
            : null
        )
      );
    }, 1000);
  }

  const getRowColor = (strike, isCall) => {
    if (theme === "light") {
      const ltpValue = symbolData[selectedSymbol?.code]?.ltp;
      if ((isCall && strike < ltpValue) || (!isCall && strike > ltpValue)) {
        return isCall ? "#FFF9E5" : "#F4F5FF"; // Light yellow for ITM call options, Light blue for ITM put options
      } else {
        return "white"; // White for OTM and ATM options
      }
    } else {
      const ltpValue = symbolData[selectedSymbol?.code]?.ltp;
      if ((isCall && strike < ltpValue) || (!isCall && strike > ltpValue)) {
        return isCall ? "#29292E" : "#29292E"; // Light yellow for ITM call options, Light blue for ITM put options
      } else {
        return "transparent"; // White for OTM and ATM options
      }
    }
  };

  const getPutRowColor = (strike) => {
    if (theme === "light") {
      const ltpValue = symbolData[selectedSymbol?.code]?.ltp;
      if (strike > ltpValue) {
        return "#F4F5FF";
      } else {
        return "white";
      }
    } else {
      const ltpValue = symbolData[selectedSymbol?.code]?.ltp;
      if (strike > ltpValue) {
        return "#29292E";
      } else {
        return "transparent";
      }
    }
  };

  // Formatting volume
  function formatVolume(volume) {
    if (volume >= 10000000) {
      return `${(volume / 10000000)?.toFixed(2)}Cr`;
    } else if (volume >= 100000) {
      return `${(volume / 100000)?.toFixed(2)}L`;
    } else if (volume >= 1000) {
      return `${(volume / 1000)?.toFixed(2)}K`;
    } else if (volume) {
      return volume % 1 === 0 ? volume?.toFixed(0) : volume?.toFixed(2);
    }
  }

  const [lots, setLots] = useState(1);

  const listenRightScrollEvent = (event) => {
    const leftSide = document.getElementById("left-side");
    const rightSide = document.getElementById("right-side");
    const maxScrollLeftLeftSide = leftSide.scrollWidth - leftSide.clientWidth;
    const maxScrollLeftRightSide =
      rightSide.scrollWidth - rightSide.clientWidth;
    const scrollPercentageRightSide =
      event.currentTarget.scrollLeft / maxScrollLeftRightSide;
    const scrollLeftLeftSide =
      (1 - scrollPercentageRightSide) * maxScrollLeftLeftSide;

    // left side
    leftSide.scrollLeft = scrollLeftLeftSide;
  };

  const listenLeftScrollEvent = (event) => {
    const leftSide = document.getElementById("left-side");
    const rightSide = document.getElementById("right-side");
    const maxScrollLeftLeftSide = leftSide.scrollWidth - leftSide.clientWidth;
    const maxScrollLeftRightSide =
      rightSide.scrollWidth - rightSide.clientWidth;
    const scrollPercentageLeftSide =
      event.currentTarget.scrollLeft / maxScrollLeftLeftSide;
    const scrollLeftRightSide =
      (1 - scrollPercentageLeftSide) * maxScrollLeftRightSide;

    // right side
    rightSide.scrollLeft = scrollLeftRightSide;
  };

  return (
    <ExpiryDatesProvider>
      <div
        className="modal fade option-chain-modal"
        id="staticBackdropOptionChainMobile"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true">
        <div
          className="modal-dialog modal-xl option-chain-modal-dialog"
          style={{ margin: "0" }}>
          <div
            className="modal-content option-chain-modal-content mt-3"
            style={{ height: "auto" }}>
            <div className="modal-body d-flex flex-column">
              <div className="">
                <div className="symbol-info d-flex flex-column">
                  <div className="symbol-name d-flex justify-content-between">
                    <div>{selectedSymbol?.scrip}</div>
                    <button
                      type="button"
                      className={
                        theme === "dark"
                          ? "btn-close-white btn-close mb-2"
                          : "btn-close mb-2"
                      }
                      data-bs-dismiss="modal"
                      aria-label="Close"></button>
                  </div>
                  <div>
                    <span className="lp">
                      {symbolData[selectedSymbol?.code]?.ltp}
                    </span>
                    <span
                      className="ch-chp me-2"
                      style={{
                        color:
                          symbolData[selectedSymbol?.code]?.change > 0
                            ? "#5CA81D" // Green color for positive change
                            : "#DD6565", // Red color for negative change
                      }}>
                      {symbolData[selectedSymbol?.code]?.change > 0 ? "+" : ""}
                      {symbolData[selectedSymbol?.code]?.change}(
                      {symbolData[selectedSymbol?.code]?.change_per}%)
                    </span>
                  </div>
                </div>
                <div className="d-flex ">
                  <ExpiryDates
                    selectedSymbol={selectedSymbol}
                    activeDate={activeDate}
                    handleSymbolSelection={handleSymbolSelection}
                    indexOfFirstDate={indexOfFirstDate}
                    indexOfLastDate={indexOfLastDate}
                  />
                </div>
              </div>
              <div
                ref={containerRef}
                className="option-chain-table mt-3"
                style={{
                  fontSize: "14px",
                  overflowX: "auto",
                  height: divVisibility ? "746px" : "auto",
                }}>
                {divVisibility && (
                  <div className="trade-placing-div-modal grid-container">
                    <div
                      className="trade-placing-child"
                      style={{ display: "flex", gap: "10px" }}>
                      <div>
                        {selectedSide === "calls"
                          ? `${callsDataCon[selectedRowIndex].strike} ${callsDataCon[selectedRowIndex].segment}`
                          : `${putsDataCon[selectedRowIndex].strike} ${putsDataCon[selectedRowIndex].segment}`}
                      </div>
                      <span className="lots-text-mobile">Lots</span>
                      <div className="number">
                        <button
                          style={{
                            background: "transparent",
                            border: "none",
                            padding: 0,
                            margin: 0,
                            textAlign: "center",
                          }}
                          onClick={() =>
                            decrementValue(selectedRowIndex, selectedSide)
                          }>
                          -
                        </button>
                        <input
                          className="lots-input-mobile"
                          type="number"
                          value={
                            selectedSide === "calls"
                              ? callsDataCon[selectedRowIndex].LotsSize
                              : putsDataCon[selectedRowIndex].LotsSize
                          }
                          onChange={(e) =>
                            handleLotSizeChange(
                              e.target.value,
                              selectedRowIndex,
                              selectedSide
                            )
                          }
                        />
                        <button
                          style={{
                            background: "transparent",
                            border: "none",
                            padding: 0,
                            margin: 0,
                          }}
                          onClick={() =>
                            incrementValue(selectedRowIndex, selectedSide)
                          }>
                          +
                        </button>
                      </div>
                      {/* <div className="scalp-svg-mobile">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="30"
                    viewBox="0 0 22 22"
                    fill="red"
                  >
                    <g clipPath="url(#clip0_509_803)">
                      <path
                        d="M9.60272 7.3488C10.8408 6.29681 13.9006 4.89919 13.9006 4.89919C13.9006 4.89919 14.4877 8.2114 14.3248 9.8279C14.0615 12.4421 10.7132 15.2731 10.7132 15.2731L7.17158 13.4138C7.17158 13.4138 7.60044 9.05009 9.60272 7.3488Z"
                        fill="url(#paint0_linear_509_803)"
                      />
                      <path
                        d="M7.22105 14.7843C7.63788 14.3656 8.16292 13.9342 8.16292 13.9342L9.71691 14.7501C9.71691 14.7501 9.62195 15.4235 9.55201 16.0081C9.48206 16.5927 7.54791 16.9997 7.54791 16.9997C7.54791 16.9997 6.80421 15.203 7.22105 14.7843Z"
                        fill="url(#paint1_linear_509_803)"
                      />
                      <path
                        d="M10.7132 15.2731C10.7132 15.2731 14.0615 12.4421 14.3248 9.8279C14.4877 8.2114 13.9006 4.89919 13.9006 4.89919C13.9006 4.89919 10.8408 6.29681 9.60272 7.3488C7.60044 9.05009 7.17158 13.4138 7.17158 13.4138M10.7132 15.2731L7.17158 13.4138M10.7132 15.2731L13.6645 16.8226L13.4579 12.1964M7.17158 13.4138L4.22026 11.8643L8.14552 9.40736M9.6802 7.20124C9.6802 7.20124 10.2557 8.25634 11.7314 9.03106C13.207 9.80578 14.4023 9.68034 14.4023 9.68034M8.16292 13.9342C8.16292 13.9342 7.63788 14.3656 7.22105 14.7843C6.80421 15.203 7.54791 16.9997 7.54791 16.9997C7.54791 16.9997 9.48206 16.5927 9.55201 16.0081C9.62195 15.4235 9.71691 14.7501 9.71691 14.7501L8.16292 13.9342Z"
                        stroke="#14142B"
                      />
                    </g>
                    <defs>
                      <linearGradient
                        id="paint0_linear_509_803"
                        x1="13.9006"
                        y1="4.89919"
                        x2="7.54789"
                        y2="16.9996"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#FF3908" />
                        <stop offset="1" stopColor="#FF7834" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_509_803"
                        x1="13.9006"
                        y1="4.89919"
                        x2="7.54789"
                        y2="16.9996"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#FF3908" />
                        <stop offset="1" stopColor="#FF7834" />
                      </linearGradient>
                      <clipPath id="clip0_509_803">
                        <rect
                          width="16"
                          height="16"
                          fill="white"
                          transform="translate(7.43726) rotate(27.6994)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div> */}
                      <div
                        className={`b-tag-mobile ${
                          selectedSide === "calls"
                            ? callsDataCon &&
                              callsDataCon[selectedRowIndex] &&
                              callsDataCon[selectedRowIndex].buyBtnState
                              ? "active"
                              : ""
                            : selectedSide === "puts" &&
                                putsDataCon &&
                                putsDataCon[selectedRowIndex] &&
                                putsDataCon[selectedRowIndex].buyBtnState
                              ? "active"
                              : ""
                        }`}
                        onClick={() =>
                          handleClick(
                            "Buy",
                            selectedSide === "calls"
                              ? callsDataCon[selectedRowIndex].LotsSize
                              : putsDataCon[selectedRowIndex].LotsSize
                          )
                        }>
                        B
                      </div>

                      <div
                        className={`s-tag-mobile ${
                          selectedSide === "calls"
                            ? callsDataCon &&
                              callsDataCon[selectedRowIndex] &&
                              callsDataCon[selectedRowIndex].sellBtnState
                              ? "active"
                              : ""
                            : selectedSide === "puts" &&
                                putsDataCon &&
                                putsDataCon[selectedRowIndex] &&
                                putsDataCon[selectedRowIndex].sellBtnState
                              ? "active"
                              : ""
                        }`}
                        onClick={() =>
                          handleClick(
                            "Sell",
                            selectedSide === "calls"
                              ? callsDataCon[selectedRowIndex].LotsSize
                              : putsDataCon[selectedRowIndex].LotsSize
                          )
                        }>
                        S
                      </div>
                    </div>
                  </div>
                )}
                <div className="calls-puts-header d-flex text-center border-bottom py-1">
                  <div
                    className="calls text-center fw-bold"
                    style={{ width: "50%" }}>
                    Calls
                  </div>
                  <div
                    className="puts text-center fw-bold"
                    style={{ width: "50%" }}>
                    Puts
                  </div>
                </div>
                <div
                  className="table-responsive tabla parallax-table d-flex "
                  style={{ position: "relative", marginBottom: "-18px" }}>
                  <table
                    className="table table-responsive left-side"
                    id="left-side"
                    style={{ width: "90%", marginBottom: "0px" }}
                    onScroll={(event) => listenLeftScrollEvent(event)}>
                    <thead>
                      <tr className="text-center">
                        <th
                          scope="col"
                          className="table-header-columns text-center">
                          OI
                        </th>
                        <th
                          scope="col"
                          className="table-header-columns text-center">
                          Volume
                        </th>
                        <th
                          scope="col"
                          className="table-header-columns text-center">
                          Chng <br />
                          Chng%
                        </th>
                        <th
                          scope="col"
                          className="table-header-columns text-center">
                          LTP
                        </th>

                        <th className="table-header-columns text-center">
                          Strike
                        </th>
                        <th className="table-header-columns text-center">IV</th>
                      </tr>
                    </thead>
                    <tbody className={theme === "dark" ? "table-dark" : ""}>
                      {optionData &&
                        optionData.options &&
                        optionData.options
                          .filter((option) => option.call) // Filter only call options
                          .map((optionGroup, index) => (
                            <tr
                              className="text-center"
                              key={optionGroup.call.instrument_name}>
                              <td
                                style={{
                                  backgroundColor: getRowColor(
                                    optionGroup.call.strike,
                                    true
                                  ),
                                }}
                                data-side="calls"
                                onClick={(event) =>
                                  handleRowClick(event, index, "calls")
                                }>
                                {formatVolume(
                                  symbolData[optionGroup.call.code]?.oi /
                                    symbolData[optionGroup.call.code]?.lot_size
                                )}
                              </td>
                              <td
                                style={{
                                  backgroundColor: getRowColor(
                                    optionGroup.call.strike,
                                    true
                                  ),
                                }}
                                data-side="calls"
                                onClick={(event) =>
                                  handleRowClick(event, index, "calls")
                                }>
                                {formatVolume(
                                  symbolData[optionGroup.call.code]?.volume /
                                    symbolData[optionGroup.call.code]?.lot_size
                                )}
                              </td>
                              <td
                                style={{
                                  backgroundColor: getRowColor(
                                    optionGroup.call.strike,
                                    true
                                  ),
                                }}
                                data-side="calls"
                                onClick={(event) =>
                                  handleRowClick(event, index, "calls")
                                }>
                                {/* {selectedSide !== null &&
                                  selectedRowIndex !== null &&
                                  selectedSide == "calls" &&
                                  selectedRowIndex == index &&
                                  divVisibility && (
                                    <div
                                      className="option-chain-row-buttons"
                                      style={{
                                        position: "absolute",
                                        // top: selectedPosition.top + "px",
                                        // left: selectedPosition.left + "px",
                                        zIndex: 9999, // To make sure it appears on top of the table
                                        display: "inline-table",
                                        left: "0px",
                                      }}>
                                      <span className="d-flex justify-content-center align-items-center gap-2">
                                        <input
                                          className="hover-lots-value px-1 text-end"
                                          value={lots}
                                          onChange={(event) =>
                                            setLots(event.target.value)
                                          }
                                        />

                                        <button
                                          className={`buy-btn ${callsDataCon && callsDataCon[index] && callsDataCon[index].buyBtnState ? "active" : ""}`}
                                          onClick={() => handleClick("Buy")}>
                                          Buy
                                        </button>
                                        <button
                                          className={`sell-btn ${callsDataCon && callsDataCon[index] && callsDataCon[index].sellBtnState ? "active" : ""}`}
                                          onClick={() => handleClick("Sell")}>
                                          Sell
                                        </button>
                                      </span>
                                    </div>
                                  )} */}
                                {/* {optionGroup.call.ltp_change} */}
                                {symbolData[optionGroup.call.code] && (
                                  <span>
                                    {symbolData[optionGroup.call.code].change}
                                    <span
                                      style={{
                                        color:
                                          symbolData[optionGroup.call.code]
                                            .change > 0
                                            ? "#5CA81D"
                                            : "#DD6565",
                                      }}>
                                      <br />(
                                      {
                                        symbolData[optionGroup.call.code]
                                          .change_per
                                      }
                                      %)
                                    </span>
                                  </span>
                                )}
                              </td>

                              <td
                                style={{
                                  backgroundColor: getRowColor(
                                    optionGroup.call.strike,
                                    true
                                  ),
                                }}
                                data-side="calls"
                                onClick={(event) =>
                                  handleRowClick(event, index, "calls")
                                }>
                                {symbolData[optionGroup.call.code]?.ltp}
                              </td>
                              {/* //striek and iv */}
                              <td className="strike">
                                {callsDataCon &&
                                  callsDataCon[index] &&
                                  (callsDataCon[index].buyBtnState ||
                                    callsDataCon[index].sellBtnState) && (
                                    <>
                                      {callsDataCon[index].buyBtnState && (
                                        <div className="b-tag">B</div>
                                      )}
                                      {callsDataCon[index].sellBtnState && (
                                        <div className="s-tag">S</div>
                                      )}
                                    </>
                                  )}
                                {optionGroup.call.strike}
                                {putsDataCon &&
                                  putsDataCon[index] &&
                                  (putsDataCon[index].buyBtnState ||
                                    putsDataCon[index].sellBtnState) && (
                                    <>
                                      {putsDataCon[index].buyBtnState && (
                                        <div className="bp-tag">B</div>
                                      )}
                                      {putsDataCon[index].sellBtnState && (
                                        <div className="sp-tag">S</div>
                                      )}
                                    </>
                                  )}
                              </td>
                              <td className="strike">{optionGroup.call.iv}</td>
                            </tr>
                          ))}
                    </tbody>
                  </table>

                  <table
                    className="table table-responsive right-side"
                    id="right-side"
                    style={{ width: "50%" }}
                    onScroll={(event) => listenRightScrollEvent(event)}>
                    <thead>
                      <tr className="text-center">
                        <th
                          scope="col"
                          className="table-header-columns text-center">
                          LTP
                        </th>
                        <th
                          scope="col"
                          className="table-header-columns text-center">
                          Chng <br />
                          Chng%
                        </th>
                        <th
                          scope="col"
                          className="table-header-columns text-center">
                          Volume
                        </th>
                        <th
                          scope="col"
                          className="table-header-columns text-center">
                          OI
                        </th>
                      </tr>
                    </thead>
                    <tbody className={theme === "dark" ? "table-dark" : ""}>
                      {optionData &&
                        optionData.options &&
                        optionData.options
                          .filter((option) => option.put) // Filter only put options
                          .map((optionGroup, index) => (
                            <tr
                              className="text-center"
                              key={optionGroup.put.instrument_name}>
                              <td
                                style={{
                                  backgroundColor: getPutRowColor(
                                    optionGroup.put.strike,
                                    true
                                  ),
                                }}
                                data-side="puts"
                                onClick={(event) =>
                                  handleRowClick(event, index, "puts")
                                }>
                                {symbolData[optionGroup.put.code]?.ltp}
                              </td>
                              <td
                                style={{
                                  backgroundColor: getPutRowColor(
                                    optionGroup.put.strike,
                                    true
                                  ),
                                }}
                                data-side="puts"
                                onClick={(event) =>
                                  handleRowClick(event, index, "puts")
                                }>
                                {/* {selectedSide !== null &&
                                  selectedRowIndex !== null &&
                                  selectedSide == "puts" &&
                                  selectedRowIndex == index &&
                                  divVisibility && (
                                    <div
                                      className="option-chain-row-buttons"
                                      style={{
                                        position: "absolute",
                                        // top: selectedPosition.top + "px",
                                        // left: selectedPosition.left + "px",
                                        zIndex: 9999, // To make sure it appears on top of the table
                                        display: "inline-table",
                                        right: "0px",
                                      }}>
                                      <span className="d-flex justify-content-center align-items-center gap-2">
                                        <input
                                          className="hover-lots-value px-1 text-end"
                                          value={lots}
                                          onChange={(event) =>
                                            setLots(event.target.value)
                                          }
                                        />

                                        <button
                                          className={`buy-btn ${putsDataCon && putsDataCon[index] && putsDataCon[index].buyBtnState ? "active" : ""}`}
                                          onClick={() => handleClick("Buy")}>
                                          Buy
                                        </button>
                                        <button
                                          className={`sell-btn ${putsDataCon && putsDataCon[index] && putsDataCon[index].sellBtnState ? "active" : ""}`}
                                          onClick={() => handleClick("Sell")}>
                                          Sell
                                        </button>
                                      </span>
                                    </div>
                                  )} */}
                                {/* {optionGroup.call.ltp_change} */}
                                {symbolData[optionGroup.put.code] && (
                                  <span>
                                    {symbolData[optionGroup.put.code].change}
                                    <span
                                      style={{
                                        color:
                                          symbolData[optionGroup.put.code]
                                            .change > 0
                                            ? "#5CA81D"
                                            : "#DD6565",
                                      }}>
                                      <br />(
                                      {
                                        symbolData[optionGroup.put.code]
                                          .change_per
                                      }
                                      %)
                                    </span>
                                  </span>
                                )}
                              </td>
                              <td
                                style={{
                                  backgroundColor: getPutRowColor(
                                    optionGroup.put.strike,
                                    true
                                  ),
                                }}
                                data-side="puts"
                                onClick={(event) =>
                                  handleRowClick(event, index, "puts")
                                }>
                                {formatVolume(
                                  symbolData[optionGroup.put.code]?.volume /
                                    symbolData[optionGroup.put.code]?.lot_size
                                )}
                              </td>
                              <td
                                style={{
                                  backgroundColor: getPutRowColor(
                                    optionGroup.put.strike,
                                    true
                                  ),
                                }}
                                data-side="puts"
                                onClick={(event) =>
                                  handleRowClick(event, index, "puts")
                                }>
                                {formatVolume(
                                  symbolData[optionGroup.put.code]?.oi /
                                    symbolData[optionGroup.put.code]?.lot_size
                                )}
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ExpiryDatesProvider>
  );
};

export default OptionChainMobile;
