import axios from "axios";

let symbolsData = null;

export const getSymbols = async () => {
  let brokerName = localStorage.getItem("broker");
  if (!brokerName) {
    console.error("Broker name not found in local storage.");
    return;
  }
  brokerName = brokerName.toLowerCase().replace(/\s+/g, "");
  if (symbolsData) {
    return symbolsData;
  } else {
    try {
      const response = await axios.post(
        "https://qf1csya8oe.execute-api.ap-south-1.amazonaws.com/default/og_allsymbols",
        {
          broker: brokerName,
        }
      );
      const data = response.data;
      symbolsData = data;
      return symbolsData;
    } catch (error) {
      console.error("Error fetching symbols:", error);
      return null;
    }
  }
};

export const getOrders = async (access_token, client_id, broker) => {
  try {
    const body = {
      access_token: access_token,
      client_id: client_id,
      broker: broker,
    };
    const endpoint_url =
      "https://uoeebamrzf.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_orders";
    const response = await axios.post(endpoint_url, body);
    let ordersData = response.data;
    return ordersData;
  } catch (error) {
    console.error("Error fetching orders:", error);
    return null;
  }
};

export const getSetting = async (
  broker,
  client_id,
  request_type,
  access_token,
  trade_mode,
  screen_mode
) => {
  // console.log("CALLING");
  try {
    const body = {
      broker: broker,
      client_id: client_id,
      request_type: request_type,
      trade_mode: trade_mode,
      screen_mode: screen_mode,
      access_token: access_token,
    };
    const endpoint_url =
      "https://ur6sq97sa9.execute-api.ap-south-1.amazonaws.com/default/og_usersettings";
    const response = await axios.post(endpoint_url, body);
    let settingData = response.data;
    return settingData;
  } catch (error) {
    console.error("Error fetching settings:", error);
    return null;
  }
};

const getScreenerDetailsData = async (path, pattern, timeframe, group) => {
  try {
    const response = await axios.post(
      "https://cmtdqn92t6.execute-api.ap-south-1.amazonaws.com/default/viewScreenerDetails",
      {
        path: path.toLowerCase(),
        name: pattern.replace(/\s+/g, " "),
        selected_timeframe: timeframe,
        selected_group: group,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error fetching screener details: " + error.message);
  }
};

export default getScreenerDetailsData;

export async function getStockDetails(stock, type) {
  try {
    const payload = {
      stock,
      type,
    };

    const response = await fetch(
      "https://42l9irgw2e.execute-api.ap-south-1.amazonaws.com/default/getStockSpecific",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    if (!response.ok) {
      // console.log(response.status)
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    // console.error('Error fetching stock details:', error);
    throw error;
  }
}

export async function getStockTechnicals(stockName, selected_timeframe) {
  try {
    const payload = {
      stockName,
      selected_timeframe,
    };

    const response = await fetch(
      "https://c4cparyxx3.execute-api.ap-south-1.amazonaws.com/default/getStockTechnicals",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    if (!response.ok) {
      // console.log(response.status)
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching technical indicators:", error);
    throw error;
  }
}

export async function getStockList() {
  try {
    const response = await fetch(
      "https://zxivhgf14j.execute-api.ap-south-1.amazonaws.com/default/getAllStockSymbols",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch stock symbols");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching stock symbols:", error.message);
    return []; // or handle the error appropriately
  }
}
