import React, { useEffect, useState, useContext } from "react";
import { useBasketContext } from "../context/BasketContext";
import { useGraphContext } from "../context/GraphContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomToastContent from "../components/CustomToastContent";
import ErrorIcon from "../images/error_icon.svg";
import SuccessIcon from "../images/success_icon.svg";
import axios from "axios";
import { useFundsData } from "../context/FundsDataContext";
import { useNavigate } from "react-router-dom";
import { ButtonContext } from "../context/ButtonsContext";
import Modal from "../modals/CommonModal";
import { useTheme } from "../context/ThemeContext";
import { Tooltip as ReactTooltip } from "react-tooltip";
import QuestionMark from "../images/QuestionMark";
import { BROKER_NAME } from "../utils/constants";

const BasketMobile = ({ isOpen, onClose }) => {
  const { fundsData } = useFundsData();
  const broker = localStorage.getItem("broker");
  const access_token = localStorage.getItem("access_token");
  const client_id = localStorage.getItem("client_id");
  const [isBuy, setIsBuy] = useState(true);
  const [isCALL, setIsCALL] = useState(true);
  const { isLive, isVirtual } = useContext(ButtonContext);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const { theme } = useTheme();

  const {
    basketData,
    removeFromBasket,
    buySellStatus,
    toggleBuySellStatus,
    handleIncrement,
    handleDecrement,
    clearBasket,
    handlePriceChange,
    handleInputBlur,
  } = useBasketContext();
  const { maxProfit, maxLoss, riskReward, breakevenRange, premium } =
    useGraphContext();
  const [activeOptions, setActiveOptions] = useState([]);

  useEffect(() => {
    setActiveOptions(Array(basketData.length).fill("intraday"));
  }, [basketData]);

  const handleOptionChange = (index, option) => {
    setActiveOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = option;
      return updatedOptions;
    });
  };

  const [checkboxStates, setCheckboxStates] = useState(
    Array(basketData.length).fill(false)
  );

  const handleToastToNavigate = (response) => {
    navigate(response.page);
    // console.log("Clicked->", response.page);
  };

  const handleCheckboxChange = (index) => {
    setCheckboxStates((prevStates) => {
      const updatedStates = [...prevStates];
      updatedStates[index] = !updatedStates[index];
      return updatedStates;
    });
  };

  const sendVirtualOrder = async (payload) => {
    try {
      const response = await axios.post(
        "https://hgb1gw5n6g.execute-api.ap-south-1.amazonaws.com/default/og_virtual_placeorder",
        payload
      );
      return response.data;
    } catch (error) {
      console.error("Error placing order:", error);
      return {
        status: false,
        message_header: "Error placing order",
        message_body: error.message,
      };
    }
  };
  const handleExecuteVirtualOrder = async () => {
    try {
      const payload = generateVirtualOrderPayload();
      // console.log("payload from basket", payload);
      const response = await sendVirtualOrder(payload);

      if (response.status) {
        clearBasket();
        onClose();
        toast.success(
          <CustomToastContent
            heading={response.message_header}
            message={response.message_body}
          />,
          {
            className: "custom-success-toast",
            icon: <img src={SuccessIcon} alt="Success Icon" />,
            onClick: () => handleToastToNavigate(response),
          }
        );
      } else {
        toast.error(
          <CustomToastContent
            heading={response.message_header}
            message={response.message_body}
          />,
          {
            className: "custom-error-toast",
            icon: <img src={ErrorIcon} alt="Error Icon" />,
          }
        );
      }
    } catch (error) {
      console.error("Error executing virtual order:", error);
      toast.error(
        <CustomToastContent
          heading="Error executing virtual order"
          message={error.message}
        />,
        {
          className: "custom-error-toast",
          icon: <img src={ErrorIcon} alt="Error Icon" />,
        }
      );
    }
  };

  const handleExecuteOrder = async () => {
    try {
      const payload = generateOrderPayload();
      // console.log("payload from basket", payload);
      const response = await sendOrder(payload);

      if (response.status) {
        clearBasket();
        onClose();
        toast.success(
          <CustomToastContent
            heading={response.message_header}
            message={response.message_body}
          />,
          {
            className: "custom-success-toast",
            icon: <img src={SuccessIcon} alt="Success Icon" />,
            onClick: () => handleToastToNavigate(response),
          }
        );
      } else {
        toast.error(
          <CustomToastContent
            heading={response.message_header}
            message={response.message_body}
          />,
          {
            className: "custom-error-toast",
            icon: <img src={ErrorIcon} alt="Error Icon" />,
          }
        );
      }
    } catch (error) {
      console.error("Error executing virtual order:", error);
      toast.error(
        <CustomToastContent
          heading="Error executing virtual order"
          message={error.message}
        />,
        {
          className: "custom-error-toast",
          icon: <img src={ErrorIcon} alt="Error Icon" />,
        }
      );
    }
  };

  // api calls
  const [orderPayload, setOrderPayload] = useState(null);
  const [marginRequired, setMarginRequired] = useState(null);
  const [charges, setCharges] = useState(null);

  const [isOrderPlaced, setIsOrderPlaced] = useState(false); // State to track if order is successfully placed

  // Function to generate order payload
  const generateOrderPayload = () => {
    const orderList = basketData.map((currData, ind) => ({
      isBuy: currData.buy_sell === "B" ? true : false,
      isCall: currData.side === "Call" ? true : false,
      isFut: false,
      code: currData.code,
      exchange: currData.exchange,
      productType: activeOptions[ind] === "normal" ? "NRML" : "MIS",
      orderType: !checkboxStates[ind] ? "MARKET" : "LIMIT",
      quantity: currData.lotSize * currData.lot_multiplier,
      price: currData.price,
      freeze_qty: currData.freeze_qty,
      strike: currData.strike,
      expiry: currData.expiryDate,
      tradingSymbol: currData.tradingSymbol,
      lot_multiplier: currData.lot_multiplier,
      scrip: currData.scrip,
      expiry: currData.expiryDate,
    }));
    return {
      order_list: orderList,
      broker: broker,
      access_token: access_token,
      client_id: client_id,
    };
  };

  const generateVirtualOrderPayload = () => {
    const orderList = basketData.map((currData, ind) => ({
      isBuy: currData.buy_sell === "B" ? true : false,
      isCall: currData.side === "Call" ? true : false,
      isFut: false,
      code: currData.code,
      exchange: currData.exchange,
      productType: activeOptions[ind] === "normal" ? "NRML" : "MIS",
      orderType: !checkboxStates[ind] ? "MARKET" : "LIMIT",
      quantity: currData.lotSize * currData.lot_multiplier,
      price: currData.price,
      freeze_qty: currData.freeze_qty,
      strike: currData.strike,
      expiry: currData.expiryDate,
      tradingSymbol: currData.tradingSymbol,
      lot_multiplier: currData.lot_multiplier,
      scrip: currData.scrip,
      expiry: currData.expiryDate,
    }));
    return {
      order_list: orderList,
      main_broker: broker,
      broker: "virtual",
      access_token: access_token,
      client_id: client_id,
    };
  };

  // Function to send order
  const sendOrder = async (payload) => {
    // console.log("payload in basket", payload);
    try {
      const response = await axios.post(
        "https://0eo1dv23p5.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_placeorder",
        payload
      );
      return response.data;
    } catch (error) {
      console.error("Error placing order:", error);
      return {
        status: false,
        message_header: "Error placing order",
        message_body: error.message,
      };
    }
  };

  // Effect to fetch margin required
  useEffect(() => {
    const generateMarginPayload = () => {
      const orderList = basketData.map((currData, ind) => ({
        isBuy: currData.buy_sell === "B" ? true : false,
        isCall: currData.side === "Call" ? true : false,
        isFut: false,
        code: currData.code,
        exchange: currData.exchange,
        productType: activeOptions[ind] === "normal" ? "NRML" : "MIS",
        orderType: !checkboxStates[ind] ? "MARKET" : "LIMIT",
        quantity: currData.lotSize * currData.lot_multiplier,
        price: currData.price,
        strike: currData.strike,
        expiry: currData.expiryDate,
        tradingSymbol: currData.tradingSymbol,
        lot_multiplier: currData.lot_multiplier,
        scrip: currData.scrip,
      }));
      return {
        order_list: orderList,
        broker: broker,
        access_token: access_token,
        client_id: client_id,
      };
    };

    const fetchMarginRequired = async () => {
      try {
        const payload = generateMarginPayload();

        const response = await axios.post(
          "https://7mwh6csmrh.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_margin",
          payload
        );

        setMarginRequired(response.data.marginRequired);
      } catch (error) {
        console.error("Error getting margin required:", error);
      }
    };
    const fetchCharges = async () => {
      try {
        const payload = await generateMarginPayload();
        const response = await axios.post(
          "https://pc933vy3c6.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_charges",
          payload
        );
        setCharges(response.data.total_charges);
      } catch (error) {
        console.error("Error getting charges required:", error);
      }
    };

    if (basketData.length > 0) {
      fetchMarginRequired();
      fetchCharges();
    }
  }, [basketData]);

  function formatNumberWithCommasAndFontWeight(value) {
    const formattedValue = parseFloat(value).toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const parts = formattedValue.split(".");
    const integerPart = parts[0];
    const decimalPart = parts[1];

    return (
      <span>
        {integerPart}.<span style={{ fontWeight: 400 }}>{decimalPart}</span>
      </span>
    );
  }
  return (
    <>
      {isOpen && (
        <>
          <div className="basket-mobile-overlay"></div>
          <div className={`basket-mobile ${isOpen ? "show" : ""}`}>
            <div className="d-flex flex-column gap-3 ">
              <div className="d-flex flex-column gap-2 mt-3">
                <div className=" d-flex justify-content-start align-items-center px-3 gap-4 w-100">
                  <div className="d-flex flex-column">
                    <span className="creator-bottom-title">Max profit</span>
                    <span className="strategy-menu-value text-success">
                      {maxProfit}
                    </span>
                  </div>
                  <div className="d-flex flex-column">
                    <span className="creator-bottom-title">Max loss </span>
                    <span className="strategy-menu-value text-danger">
                      {maxLoss}
                    </span>
                  </div>
                  <div className="d-flex flex-column">
                    <span className="creator-bottom-title">Risk reward </span>
                    <span className="strategy-menu-value">{riskReward}</span>
                  </div>
                  <button
                    type="button"
                    className={
                      theme === "dark"
                        ? "btn-close-white btn-close ms-auto"
                        : "btn-close ms-auto"
                    }
                    onClick={() => {
                      onClose();
                    }}></button>
                </div>
                <div className=" d-flex justify-content-start align-items-center px-3 gap-4 w-100">
                  <div className="d-flex flex-column ">
                    <span className="creator-bottom-title">
                      Breakeven between
                    </span>
                    <span className="strategy-menu-value">
                      {breakevenRange}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="px-0"
                style={{ maxHeight: "360px", overflowY: "auto" }}>
                <div className="table-container">
                  <table className="strategy-tablee">
                    <thead>
                      <tr className="text-center tr-strategy-headerr">
                        <tr
                          className="d-flex justify-content-start align-items-center gap-3"
                          style={{ width: "98vw", padding: "0px 12px" }}>
                          <th scope="col" className="py-2">
                            B/S
                          </th>
                          <th scope="col" className="py-2">
                            Instrument
                          </th>
                          <th scope="col" className="py-2"></th>
                        </tr>
                        <tr
                          className="d-flex justify-content-between align-items-center "
                          style={{ width: "98vw", padding: "0px 12px" }}>
                          <th scope="col" className="pb-2">
                            Lots
                          </th>
                          <th scope="col">Product type</th>
                          <th scope="col">Market/Limit</th>
                        </tr>
                      </tr>
                    </thead>
                  </table>
                  <div style={{ overflowY: "auto", maxHeight: "300px" }}>
                    <table>
                      <tbody className="tbody">
                        {basketData.map((item, index) => (
                          <tr
                            key={index}
                            className="strategy-table-roww border-bottom"
                            style={{ height: "66px" }}>
                            <tr
                              className="d-flex  align-items-center justify-content-start px-2 pb-2"
                              style={{ width: "98vw", padding: "0px 12px" }}>
                              <td className="basket-table-cell text-center">
                                <div
                                  className={`bs-value d-flex align-items-center justify-content-center ${
                                    item["buy_sell"] === "B" ? "buy" : "sell"
                                  }`}
                                  style={{
                                    height: "24px",
                                    width: "24px",
                                    borderRadius: "4px",

                                    background:
                                      item["buy_sell"] === "B"
                                        ? "#70D11F"
                                        : "#FF3908",
                                    color: "white",
                                  }}
                                  onClick={() => toggleBuySellStatus(index)}
                                  role="button">
                                  {item["buy_sell"] === "B" ? "B" : "S"}
                                </div>
                              </td>
                              <td className="basket-table-cell text-start ">
                                <div className="d-flex align-items-center ps-2">
                                  {item.instrumentName}
                                </div>
                              </td>
                              <td className="basket-table-action basket-table-cell d-flex align-items-center justify-content-center gap-4 ms-auto">
                                <div className="d-flex align-items-center justify-content-center">
                                  <svg
                                    role="button"
                                    onClick={() => removeFromBasket(index)}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none">
                                    <path
                                      d="M12.6364 3.90909V3.18182C12.6364 1.97683 11.6595 1 10.4545 1H7.54545C6.34047 1 5.36364 1.97683 5.36364 3.18182V3.90909M1 3.90909H17M11.1818 8.27273V11.9091M6.81818 8.27273V11.9091M2.45455 3.90909H15.5455L15.0636 13.5452C14.9669 15.4805 13.3695 17 11.4318 17H6.56818C4.63045 17 3.03312 15.4805 2.93635 13.5452L2.45455 3.90909Z"
                                      stroke="#C1C1C1"
                                      strokeLinecap="round"
                                    />
                                  </svg>
                                </div>
                              </td>
                            </tr>
                            <tr
                              className="d-flex  align-items-center justify-content-between px-2 pb-2"
                              style={{ width: "98vw", padding: "0px 12px" }}>
                              <td className="basket-table-cell">
                                <div className="strategy-lots d-flex align-items-center justify-content-between px-2">
                                  <span
                                    role="button"
                                    onClick={() => handleDecrement(index)}
                                    style={{
                                      color: "#C1C1C1",
                                      fontSize: "20px",
                                      fontWeight: "500",
                                      lineHeight: "0px",
                                    }}>
                                    -
                                  </span>
                                  <span>{item?.lotSize}</span>
                                  <span
                                    role="button"
                                    className="plus"
                                    onClick={() => handleIncrement(index)}
                                    style={{
                                      color: "#C1C1C1",
                                      fontSize: "20px",
                                      fontWeight: "500",
                                      lineHeight: "0px",
                                    }}>
                                    +
                                  </span>
                                </div>
                              </td>
                              <td className="basket-table-cell">
                                <div className="toggle-container">
                                  <button
                                    className={`toggle-button ${
                                      activeOptions[index] === "intraday"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleOptionChange(index, "intraday")
                                    }>
                                    Intraday
                                  </button>
                                  <button
                                    className={`toggle-button ${
                                      activeOptions[index] === "normal"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleOptionChange(index, "normal")
                                    }>
                                    Normal
                                  </button>
                                </div>
                              </td>
                              <td className="basket-table-cell">
                                <div className="price d-flex align-items-center justify-content-between px-2">
                                  <input
                                    type="checkbox"
                                    checked={!checkboxStates[index]}
                                    onChange={() => handleCheckboxChange(index)}
                                  />
                                  {!checkboxStates[index] ? (
                                    <div className="market">MARKET</div>
                                  ) : (
                                    <input
                                      type="text"
                                      value={item.price}
                                      className="price-input pe-1"
                                      onChange={(e) =>
                                        handlePriceChange(e, index)
                                      }
                                      onBlur={() => handleInputBlur(index)}
                                    />
                                  )}
                                </div>
                              </td>
                            </tr>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                className="creator-bottom d-flex mb-2 gap-2 flex-column border-top "
                style={{ padding: "12px 12px", marginTop: "auto" }}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex gap-2">
                    <span className="creator-bottom-title">
                      Margin available
                    </span>
                    <span className="creator-bottom-value">
                      {" "}
                      ₹{" "}
                      {formatNumberWithCommasAndFontWeight(
                        fundsData.marginAvailable
                      )}
                    </span>
                  </div>
                  <div className="d-flex gap-2">
                    <span className="creator-bottom-title">
                      Charges{" "}
                      <span data-tooltip-id="chargess">
                        <QuestionMark />
                      </span>
                    </span>
                    <span className="creator-bottom-value">
                      {formatNumberWithCommasAndFontWeight(charges)}
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex gap-2">
                    <span className="creator-bottom-title">
                      Estmtd margin{" "}
                      <span data-tooltip-id="estmtd-margin">
                        <QuestionMark />
                      </span>
                    </span>
                    <span className="creator-bottom-value">
                      {formatNumberWithCommasAndFontWeight(marginRequired)}
                    </span>
                  </div>
                  <div className="d-flex gap-2">
                    <span className="creator-bottom-title">
                      Premium{" "}
                      <span data-tooltip-id="premium">
                        <QuestionMark />
                      </span>
                    </span>
                    <span className="creator-bottom-value">₹ {premium}</span>
                  </div>
                </div>
              </div>
              <div
                className="d-flex mt-3 border-top justify-content-between gap-3"
                style={{
                  padding: "12px",
                  marginTop: "auto",
                }}>
                {BROKER_NAME === "gmail" ? (
                  <button
                    className="virtual-button bg-transparent w-50"
                    onClick={handleExecuteVirtualOrder}>
                    Add virtual position
                  </button>
                ) : (
                  ""
                )}
                {isLive ? (
                  <button
                    className="basket-order-btn w-50 m-auto"
                    onClick={handleExecuteOrder}>
                    Execute all orders
                  </button>
                ) : (
                  <>
                    <button
                      className="basket-order-not w-50"
                      onClick={() => setOpenModal(!openModal)}>
                      Execute all orders
                    </button>
                    <Modal
                      isOpen={openModal}
                      onClose={() => setOpenModal(!openModal)}></Modal>
                  </>
                )}
              </div>
              <ReactTooltip
                className="mobile-tooltip"
                id="estmtd-margin"
                place="top"
                variant={theme === "dark" ? "light" : "dark"}
                content="Margin estimate to execute the trade"
              />
              <ReactTooltip
                className="mobile-tooltip"
                id="chargess"
                place="top"
                variant={theme === "dark" ? "light" : "dark"}
                content="Charges estimate that may incur on trade"
              />
              <ReactTooltip
                className="mobile-tooltip"
                id="premium"
                place="top"
                variant={theme === "dark" ? "light" : "dark"}
                content="Negative premium indicates premium paid and positive indicates premium received"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BasketMobile;
