import { createContext, useContext, useState } from "react";

const BuySellModalContext = createContext();

export const BuySellModalProvider = ({ children }) => {
  const [buySellModalData, setBuySellModalData] = useState([]);
  const addToBuySellModal = (dataObject) => {
    setBuySellModalData([dataObject]);
  };

  const toggleBuySellStatus = (index) => {
    let dataObject = buySellModalData[index];
    dataObject["buy_sell"] =
      buySellModalData[index]["buy_sell"] === "B" ? "S" : "B";
    setBuySellModalData((prevData) => [...prevData]);
  };
  const isMultipleOfFiveCents = (value) => {
    const decimalPart = value % 1;
    const scaledDecimal = Math.round(decimalPart * 100);
    return scaledDecimal % 5 === 0;
  };

  const handlePriceChange = (e) => {
    // console.log("render");
    const inputElement = e.target;
    let inputValue = e.target.value;
    // console.log(inputValue);
    const cursorPosition = inputElement.selectionStart;
    if (/^\d*\.?\d*$/.test(inputValue)) {
      const updatedItem = {
        ...buySellModalData[0],
        price: inputValue === "" ? "" : inputValue,
        //making it as string, because component render is casuing the cursor to get it's dedault posotion
      };
      setBuySellModalData([updatedItem]);

      setTimeout(() => {
        inputElement.setSelectionRange(cursorPosition, cursorPosition);
      }, 0);
    }
  };

  const handleTriggerPriceChange = (e) => {
    const inputElement = e.target;
    let inputValue = e.target.value;

    const cursorPosition = inputElement.selectionStart;

    if (/^\d*\.?\d*$/.test(inputValue)) {
      const updatedItem = {
        ...buySellModalData[0],
        trigger_price: inputValue === "" ? "" : inputValue,
      };
      setBuySellModalData([updatedItem]);

      setTimeout(() => {
        inputElement.setSelectionRange(cursorPosition, cursorPosition);
      }, 0);
    }
  };

  const handleIncrement = () => {
    setBuySellModalData((prevData) => {
      const newData = [...prevData];
      newData.forEach((dataObject) => {
        dataObject.lotSize += 1;
      });
      return newData;
    });
  };

  const handleDecrement = () => {
    setBuySellModalData((prevData) => {
      const newData = [...prevData];
      newData.forEach((dataObject) => {
        if (dataObject.lotSize > 1) {
          dataObject.lotSize -= 1;
        }
      });
      return newData;
    });
  };

  const clearBuySellModalData = () => {
    if (buySellModalData.length > 0) {
      setBuySellModalData([]);
    }
  };

  return (
    <BuySellModalContext.Provider
      value={{
        buySellModalData,
        setBuySellModalData,
        addToBuySellModal,
        toggleBuySellStatus,
        handleIncrement,
        handleDecrement,
        clearBuySellModalData,
        handlePriceChange,
        handleTriggerPriceChange,
        isMultipleOfFiveCents,
      }}>
      {children}
    </BuySellModalContext.Provider>
  );
};

export const useBuySellModalContext = () => {
  return useContext(BuySellModalContext);
};
