import FiiTableComponent from "./FiiTableComponent";

const FiiDiiDesktop = () => {
  return (
    <div className="fiidii-desktop">
      <FiiTableComponent />
    </div>
  );
};

export default FiiDiiDesktop;
