import React, { useEffect, useState } from "react";
import axios from "axios";

const FiiTableComponent = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const apiURL =
    "https://wjurioweg2.execute-api.ap-south-1.amazonaws.com/default/fiiDiiData";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiURL);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentData = data.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(data.length / rowsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const [activeButton, setActiveButton] = useState("cash");
  const [activeTable, setActiveTable] = useState("cash");
  const [selectedFandO, setSelectedFandO] = useState("Index");
  const handleButtonClick = (button) => {
    setActiveButton(button);
    setActiveTable(button);
  };

  function formatVolume(volume) {
    if (volume){
      return parseFloat(volume).toLocaleString()
    } else {
      return `-`;
    }
  }

  return (
    <div className="fii-dii-container">
      <div className="d-flex justify-content-between">
        <div className="graph-btns d-flex gap-2 mb-3">
          <button
            className={`filter-btns ${activeButton === "cash" ? "active" : ""}`}
            onClick={() => handleButtonClick("cash")}>
            Cash
          </button>
          {/* <button
            className={`filter-btns ${activeButton === "f&o" ? "active" : ""}`}
            onClick={() => handleButtonClick("f&o")}>
            F&O
          </button> */}
        </div>
        {/* {activeButton === "f&o" && (
          <div className="ms-auto mb-3">
            <div className="form-group">
              <select
                id="futuresOptionsDropdown"
                className="form-select cursor-pointer fiidii-select"
                value={selectedFandO}
                onChange={handleCheckboxChange}>
                <option value="Index">Index</option>
                <option value="Stock">Stock</option>
              </select>
            </div>
          </div>
        )} */}
      </div>
      {activeButton === "cash" && (
        <div className="group-table d-flex flex-column gap-3">
          <div className="group-container">
            <div style={{ padding: "1rem" }}>
              <div className="table-responsive">
                <table className="table table-borderless fiidii-table w-100">
                  <thead>
                    <tr className="text-center position-table-header border">
                      <th colSpan={4} className="border">
                        FII
                      </th>
                      <th colSpan={3}>DII</th>
                    </tr>
                    <tr className="text-center position-table-header">
                      <th scope="col" className="text-start ps-4">
                        Date
                      </th>
                      <th>Gross Purchase</th>
                      <th>Gross Sales</th>
                      <th scope="col">Net Purchase / Sales</th>
                      <th>Gross Purchase</th>
                      <th>Gross Sales</th>
                      <th scope="col">Net Purchase / Sales</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.length > 0 ? (
                      currentData.map((entry, index) => (
                        <tr key={index} className="text-center">
                          <td className="text-start ps-4">{entry.date}</td>
                          <td>{formatVolume(entry.fii_equitygrosspurchase) || "-"}</td>
                          <td>{formatVolume(entry.fii_equitygrosssales) || "-"}</td>
                          <td style={{
                            color:
                            entry.fii_equitynetinvestment >=
                              0
                                ? "#5CA81D"
                                : "#DD6565",
                          }}>{formatVolume(entry.fii_equitynetinvestment) || "-"}</td>
                          <td>{formatVolume(entry.dii_equitygrosspurchase) || "-"}</td>
                          <td>{formatVolume(entry.dii_equitygrosssales) || "-"}</td>
                          <td style={{
                            color:
                            entry.dii_equitynetinvestment >=
                              0
                                ? "#5CA81D"
                                : "#DD6565",
                          }}>{formatVolume(entry.dii_equitynetinvestment) || "-"}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={7}>Loading...</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Pagination buttons*/}
          {totalPages > 1 && (
            <div className="pagination d-flex gap-3 justify-content-center">
              <button
                className="filter-btns"
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}>
                Previous
              </button>
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className={
                    currentPage === index + 1
                      ? "active-page filter-btns"
                      : "filter-btns"
                  }>
                  {index + 1}
                </button>
              ))}
              <button
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
                className="filter-btns">
                Next
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FiiTableComponent;
