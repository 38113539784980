import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import ABWebSocketContext from "../../context/ABWebsocketContext";
import { useOptionChainContext } from "../../context/OptionChainContext";
import { useBasketContext } from "../../context/BasketContext";
import { getSymbols } from "../../utils/api";
import SymbolSelection from "../../components/SymbolSelection";
import axios from "axios";
import ExpiryDates from "../../components/ExpiryDates";
import BasketModal from "../../modals/BasketModal";
import SettingsMobile from "../../modals/SettingsMobile";
import OptionChainTableMobile from "../../components/OptionChainTableMobile";
import BasketMobile from "../../modals/BasketMobile";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useTheme } from "../../context/ThemeContext";
import WebsocketWrapperContext from "../../context/WebsocketWrapperContext";
import { BROKER_NAME } from "../../utils/constants";

let triggerSubscribe = true;
let connectionEstablised = false;

const OptionChainMobile = () => {
  const {
    updateSymbolList,
    websocketConnection,
    checkWebsocketConnection,
    subscribeToSymbols,
    unsubscribeToSymbols,
    symbolList,
    symbolData,
  } = useContext(WebsocketWrapperContext);
  const { theme } = useTheme();
  const { basketData } = useBasketContext();
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [activeDate, setActiveDate] = useState(0);
  const [expiryDays, setExpiryDays] = useState(0);
  const [maxPain, setMaxPain] = useState(0);
  const [support, setSupport] = useState(0);
  const [resistance, setResistance] = useState(0);
  const [objectData, setObjectData] = useState({});
  const [allTradingSymbols, setAllTradingSymbols] = useState({});
  const { optionData, setOptionData } = useOptionChainContext();
  const [tempOptionData, setTempOptionData] = useState({});
  const [showCalls, setShowCalls] = useState(true);
  const [showPuts, setShowPuts] = useState(true);
  const [showDelta, setShowDelta] = useState(false);
  const [showTheta, setShowTheta] = useState(false);
  const [showGamma, setShowGamma] = useState(false);
  const [showVega, setShowVega] = useState(false);
  const [showOIChng, setShowOIChng] = useState(false);
  const [showOIBuilddup, setShowOIBuilddup] = useState(false);
  const [searchParams] = useSearchParams();
  const [numberOfRows, setNumberOfRows] = useState(20);

  useEffect(() => {
    const storedSymbol = sessionStorage.getItem("selectedSymbol");
    if (storedSymbol) {
      setSelectedSymbol(JSON.parse(storedSymbol));
    } else {
      const fetchSymbols = async () => {
        const symbols = await getSymbols();
        if (symbols) {
          const niftySymbol = symbols.find(
            (symbol) => symbol.scrip === "NIFTY"
          );
          setSelectedSymbol(niftySymbol);
          sessionStorage.setItem("selectedSymbol", JSON.stringify(niftySymbol));
        }
      };

      fetchSymbols();
    }
  }, []);

  const fetchDataForSymbol = async (symbol, date) => {
    let brokerName = localStorage.getItem("broker");

    if (!brokerName) {
      console.error("Broker name not found in local storage.");
      return;
    }

    brokerName = brokerName.toLowerCase().replace(/\s+/g, "");
    const apiEndpoint = `https://y18wfq6w5b.execute-api.ap-south-1.amazonaws.com/default/og_expirysymbols`;
    try {
      // if (Object.keys(tempOptionData).length === 0) {
      const response = await axios.post(apiEndpoint, {
        id: symbol + date,
        broker: brokerName,
      });
      const data = response.data;

      setTempOptionData(data);
      if (Object.keys(allTradingSymbols).length > 0) {
        unsubscribeToSymbols(
          allTradingSymbols,
          data?.options[0]?.call.exchange
        );
      }
      // }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    const symbolFromUrl = searchParams.get("trading_symbol");
    if (symbolFromUrl && symbolFromUrl !== selectedSymbol?.scrip) {
      const fetchSymbols = async () => {
        try {
          const symbols = await getSymbols();
          const tradingSymbolFromURL = symbols.find(
            (symbol) => symbol.scrip === symbolFromUrl
          );
          if (tradingSymbolFromURL) {
            setSelectedSymbol(tradingSymbolFromURL);
            sessionStorage.setItem(
              "selectedSymbol",
              JSON.stringify(tradingSymbolFromURL)
            );
            subscribeToSymbols([tradingSymbolFromURL.code], "NSE");
          }
        } catch (error) {
          console.error("Error fetching symbols:", error);
        }
      };
      fetchSymbols();
    }
  }, [searchParams, fetchDataForSymbol, subscribeToSymbols, selectedSymbol]);

  const handleSymbolSelection = async (symbol, date) => {
    sessionStorage.setItem("selectedSymbol", JSON.stringify(symbol));
    setSelectedSymbol(symbol);
    setActiveDate(date); // Setting the active date
    const targetDateTime = new Date(date);
    const currentDate = new Date();
    let timeToExpiration = (
      (targetDateTime - currentDate) /
      (24 * 60 * 60 * 1000)
    ).toFixed(0);
    setExpiryDays(parseInt(timeToExpiration) + 1);
    const formattedDate = date.replaceAll(" ", "%20");
    await fetchDataForSymbol(symbol.scrip, formattedDate); // Await the completion of the functio
  };

  const getCodeTradingSymbolMappings = (data, key) => {
    const codeTradingSymbol = [];
    for (const item of data) {
      const { code } = item[key];
      codeTradingSymbol.push(code);
    }
    return codeTradingSymbol;
  };

  function formatVolume(volume) {
    if (volume >= 10000000) {
      return `${(volume / 10000000)?.toFixed(2)}Cr`;
    } else if (volume >= 100000) {
      return `${(volume / 100000)?.toFixed(2)}L`;
    } else if (volume >= 1000) {
      return `${(volume / 1000)?.toFixed(2)}K`;
    } else if (volume !== "NaN" && volume) {
      return `${volume}`;
    } else {
      return volume;
    }
  }

  useEffect(() => {
    const fetchDefaultData = () => {
      if (
        selectedSymbol &&
        selectedSymbol.option_expiries &&
        selectedSymbol.option_expiries.length > 0
      ) {
        const defaultDate = selectedSymbol.option_expiries[0]; // Selecting the first date
        const formattedDate = defaultDate.replaceAll(" ", "%20");
        setActiveDate(defaultDate);
        const targetDateTime = new Date(defaultDate);
        const currentDate = new Date();
        let timeToExpiration = (
          (targetDateTime - currentDate) /
          (24 * 60 * 60 * 1000)
        ).toFixed(0);
        setExpiryDays(parseInt(timeToExpiration) + 1);
        if (optionData) {
          if (selectedSymbol.scrip + defaultDate !== optionData["id"]) {
            fetchDataForSymbol(selectedSymbol.scrip, formattedDate);
          }
          let callsdata = optionData.options?.map((option) => option.call);
          let putsdata = optionData.options?.map((option) => option.put);
          setCalls(callsdata);
          setPuts(putsdata);
        } else {
          fetchDataForSymbol(selectedSymbol.scrip, formattedDate);
        }
      }
    };
    fetchDefaultData();
  }, [selectedSymbol]);

  useEffect(() => {
    if (optionData && optionData.options) {
      const strikeOpenInterest = {};
      let callMaxOi = 0;
      let putMaxOi = 0;
      let support = 0;
      let resistance = 0;
      // Iterate through the options array and accumulate open interest
      optionData.options.forEach((option) => {
        const callOi = parseFloat(symbolData[option.call.code]?.oi);
        const putOi = parseFloat(symbolData[option.put.code]?.oi);
        let spot = parseFloat(symbolData[selectedSymbol?.code]?.ltp);
        if (callOi > callMaxOi && spot < parseFloat(option.strike)) {
          callMaxOi = callOi;
          resistance = option.strike;
        }
        if (putOi > putMaxOi && spot > parseFloat(option.strike)) {
          putMaxOi = putOi;
          support = option.strike;
        }
        if (!strikeOpenInterest[option.call.strike]) {
          strikeOpenInterest[option.call.strike] = { callOi: 0, putOi: 0 };
        }

        strikeOpenInterest[option.call.strike].callOi += callOi;
        strikeOpenInterest[option.call.strike].putOi += putOi;
      });
      setResistance(resistance);
      setSupport(support);
      let maxPainStrike = null;
      let maxTotalOi = 0;

      for (const strike in strikeOpenInterest) {
        const { callOi, putOi } = strikeOpenInterest[strike];
        const totalOi = callOi + putOi;

        if (totalOi > maxTotalOi) {
          maxTotalOi = totalOi;
          maxPainStrike = strike;
        }
      }
      if (maxPainStrike) {
        setMaxPain(maxPainStrike);
      }
    }
  }, [optionData, symbolData, maxPain]);

  useLayoutEffect(() => {
    if (
      Object.keys(tempOptionData).length > 0 &&
      symbolData[selectedSymbol?.code]
    ) {
      let underlying_ltp = symbolData[selectedSymbol?.code]?.ltp;
      let data = { ...tempOptionData };
      const formattedData = data["options"].map((item) => ({
        ...item,
        strike: parseInt(item?.strike),
      }));

      let minDiff = Math.abs(
        formattedData[0]?.strike - parseFloat(underlying_ltp)
      );
      let closestIdx = 0;

      // Iterate through the array to find the closest index
      formattedData.forEach((item, index) => {
        const diff = Math.abs(item.strike - parseFloat(underlying_ltp));
        if (diff < minDiff) {
          minDiff = diff;
          closestIdx = index;
        }
      });
      const half = Math.floor(numberOfRows / 4);

      const selected = formattedData.slice(
        Math.max(0, closestIdx - half),
        Math.min(formattedData.length, closestIdx + half)
      );
      data["options"] = selected;
      setOptionData(data);
      // setTempOptionData(data);

      const call = getCodeTradingSymbolMappings(selected, "call");
      const put = getCodeTradingSymbolMappings(selected, "put");
      let callsdata = optionData?.options?.map((option) => option?.call);
      let putsdata = optionData?.options?.map((option) => option?.put);
      setCalls(callsdata);
      setPuts(putsdata);
      const tradingSymbols = call.concat(put);
      setAllTradingSymbols(tradingSymbols);
      subscribeToSymbols(tradingSymbols, optionData?.options[0]?.call.exchange);
    }
  }, [tempOptionData, symbolData, numberOfRows]);

  // For dates scroll
  const [currentPage, setCurrentPage] = useState(1);
  const datesPerPage = 2;
  const totalPages =
    selectedSymbol && selectedSymbol.option_expiries
      ? Math.ceil(selectedSymbol.option_expiries.length / datesPerPage)
      : 0;
  // console.log("Expiries", selectedSymbol.option_expiries);
  const indexOfLastDate = currentPage * datesPerPage;
  const indexOfFirstDate = indexOfLastDate - datesPerPage;
  const showPrevious = currentPage > 1;
  const showNext = currentPage < totalPages;
  const [calls, setCalls] = useState();
  const [puts, setPuts] = useState();
  const handleNextPage = () => {
    if (showNext) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (showPrevious) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    connectionEstablised = checkWebsocketConnection();
    if (connectionEstablised && allTradingSymbols.length > 0) {
      if (triggerSubscribe) {
        subscribeToSymbols(allTradingSymbols, "NSE");
        if (Object.keys(allTradingSymbols).length !== 0) {
          triggerSubscribe = false;
        }
      }
    } else {
      triggerSubscribe = true;
    }
  }, [symbolData]);

  // Define the updateSelectedSymbol function
  const updateSelectedSymbol = (symbol) => {
    setSelectedSymbol(symbol);
  };

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
  };

  const [symbols, setSymbols] = useState({});
  const [selectedData, setData] = useState([]);

  const getOptionData = async (symbol, date) => {
    let brokerName = localStorage.getItem("broker");

    if (!brokerName) {
      console.error("Broker name not found in local storage.");
      return;
    }

    brokerName = brokerName.toLowerCase().replace(/\s+/g, "");
    const apiEndpoint = `https://y18wfq6w5b.execute-api.ap-south-1.amazonaws.com/default/og_expirysymbols`;
    try {
      const response = await axios.post(apiEndpoint, {
        id: symbol + date,
        broker: brokerName,
      });
      const data = response.data;
      // setOptionData(data);
      // Extracting trading symbols from the received data and updating allTradingSymbols
      // TODO: Remove getting only 20 and add logic to get OTM 30 and ITM 30

      const formattedData = data["options"].map((item) => ({
        ...item,
        strike: parseInt(item.strike),
      }));

      return data;
    } catch (error) {
      console.log("Error: ", error);
    }
  };
  // Extracting strike
  const extractStrikeValues = (optionData) => {
    if (!optionData || !Array.isArray(optionData.options)) {
      return [];
      // return optionData.options.map((options) => options.strike);
    }
    return optionData.options.map((options) => options.strike);
  };

  const handleCallback2 = async ([childData, buy_sell]) => {
    // Update the name in the component's state
    let dataToAdd = childData;
    // console.log(childData);
    let dt = dataToAdd.instrumentName.split(" ");
    let date = dataToAdd.expiryDate;
    const dDate = date.replaceAll("%20", " ");
    const newObject = {
      isBuy: buy_sell == "B" ? true : false,
      isCALL: childData.side == "Call" ? true : false,
      strike: parseInt(childData.strike),
      expiry: dDate ? dDate : dt[3] + " " + dt[2] + " 20" + dt[1],
      scrip: dt[0],
      lotSize: 1,
      code: childData.code,
      tradingSymbol: childData.tradingSymbol,
      freeze_qty: childData.freeze_qty,
      exchange: childData.exchange,
      lot_multiplier: childData.lot_multiplier,
      allstrks: [],
      allData: [],
      strike: childData.strike,
      expiry: childData.expiryDate,
      tradingSymbol: childData.tradingSymbol,
      lot_multiplier: childData.lot_multiplier,
    };
    // console.log(newObject);
    setObjectData(newObject);
    const newobj = [newObject.code];
    setSymbols(newobj);
    // updateSymbol();
    const formattedDate = newObject.expiry.replaceAll(" ", "%20");
    const alldt = await getOptionData(selectedSymbol.scrip, formattedDate);
    setTimeout(() => {
      let allstrk = extractStrikeValues(alldt);
      newObject.allstrks = allstrk;
      newObject.allData = alldt?.options;
      setData([...selectedData, newObject]);
    }, 200);
  };

  function formatNumberWithFontWeight(number) {
    const floatValue = parseFloat(number);
    const formattedValue = floatValue.toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const parts = formattedValue.split(".");
    if (parts[1] === "00") {
      // No decimal places, format as an integer
      return (
        <span style={{ fontWeight: 700 }}>
          {parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
        </span>
      );
    }

    // Has decimal places, format as before
    return (
      <span>
        {parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}.{""}
        <span style={{ fontWeight: 400 }}>{parts[1]}</span>
      </span>
    );
  }
  const showMostTradedSymbols = false;
  const [isBasketOpen, setIsBasketOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const handleOpenBasket = () => {
    setIsBasketOpen(true);
  };

  const handleCloseBasket = () => {
    setIsBasketOpen(false);
  };

  const handleOpenSettings = () => {
    setIsSettingsOpen(true);
  };

  const handleCloseSettings = () => {
    setIsSettingsOpen(false);
  };

  // useEffect(() => {
  //   const handleOutsideClick = (e) => {
  //     if (isBasketOpen && e.target.closest(".basket-mobile") === null) {
  //       handleCloseBasket();
  //     }
  //   };

  //   if (isBasketOpen) {
  //     document.addEventListener("click", handleOutsideClick);
  //   } else {
  //     document.removeEventListener("click", handleOutsideClick);
  //   }

  //   return () => {
  //     document.removeEventListener("click", handleOutsideClick);
  //   };
  // }, [isBasketOpen]);
  // const { scrip } = useParams();
  // console.log("SCrip value from position page:", scrip);
  const handleSliderChange = (value) => {
    setNumberOfRows(value);
  };
  const sliderLength = tempOptionData?.options?.length;

  //pcr calculations

  const [pcrData, setPcrData] = useState({
    pcrOi: 0,
    pcrVolume: 0,
    percentage: 0,
  });

  useEffect(() => {
    if (optionData && optionData.options && symbolData) {
      let totalPutOi = 0;
      let totalCallOi = 0;
      let totalPutVolume = 0;
      let totalCallVolume = 0;
      let previousCallOi = 0;
      let previousPutOi = 0;

      optionData.options.forEach((option) => {
        const callOi = parseFloat(symbolData[option.call.code]?.oi) || 0;
        const putOi = parseFloat(symbolData[option.put.code]?.oi) || 0;
        const callVolume =
          parseFloat(symbolData[option.call.code]?.volume) || 0;
        const putVolume = parseFloat(symbolData[option.put.code]?.volume) || 0;
        const previousPut = parseFloat(option.call.previous_oi) || 0;
        const previousCall = parseFloat(option.put.previous_oi) || 0;

        totalPutOi += putOi;
        totalCallOi += callOi;
        totalPutVolume += putVolume;
        totalCallVolume += callVolume;
        previousPutOi += previousPut;
        previousCallOi += previousCall;
      });
      const pcrOi = totalPutOi / totalCallOi;
      const previousPcrOi = previousPutOi / previousCallOi;
      const pcrVolume = totalPutVolume / totalCallVolume;
      const changePer = previousPcrOi
        ? ((pcrOi - previousPcrOi) / previousPcrOi) * 100
        : 0;

      setPcrData({
        pcrOi,
        pcrVolume,
        changePer,
      });
    }
  }, [optionData, selectedSymbol]);

  return (
    <div
      className={`d-grid mb-5 ${BROKER_NAME === "gmail" ? "" : "mt-3"}`}
      style={{ gridColumn: "span 4" }}>
      <div className="d-grid" style={{ gridColumn: "span 4" }}>
        <SymbolSelection
          selectedSymbol={selectedSymbol}
          fetchDataForSymbol={fetchDataForSymbol}
          updateSelectedSymbol={updateSelectedSymbol}
          handleSymbolSelection={handleSymbolSelection}
          showMostTradedSymbols={showMostTradedSymbols}></SymbolSelection>
      </div>
      <div
        style={{
          gap: 10,
          display: "flex",
          alignItems: "center",
          flex: 1,
          paddingTop: 20,
          gridColumn: "span 4",
          justifyContent: "space-between",
        }}>
        <div style={{ fontSize: 14 }} className="expiry-date-title">
          PCR OI:{" "}
          <span style={{ fontWeight: "bold" }}>
            {pcrData.pcrOi?.toFixed(2)}
          </span>{" "}
          (
          <span
            className={`text-${pcrData.changePer > 0 ? "success" : "danger"}`}
            style={{ fontWeight: "bold" }}>
            {pcrData.changePer?.toFixed(2)}%
          </span>
          ){" "}
        </div>
        <div className="expiry-date-title" style={{ fontSize: 14 }}>
          PCR Volume:{" "}
          <span style={{ fontWeight: "bold" }}>
            {pcrData.pcrVolume?.toFixed(2)}
          </span>
        </div>
      </div>
      <div className="d-grid" style={{ gridColumn: "span 4" }}>
        <div className="d-flex align-items-center ">
          <div className="d-flex justify-content-center align-items-center mt-3 ">
            <ExpiryDates
              selectedSymbol={selectedSymbol}
              activeDate={activeDate}
              handleSymbolSelection={handleSymbolSelection}
              expiryDays={expiryDays}
              showTitle={false}
              showDaysInButton={false}
            />
            {/* <ExpiryDates
              selectedSymbol={selectedSymbol}
              activeDate={activeDate}
              handleSymbolSelection={handleSymbolSelection}
              indexOfFirstDate={indexOfFirstDate}
              indexOfLastDate={indexOfLastDate}
            /> */}
          </div>
          <div
            className="d-flex align-items-center justify-content-end mt-3"
            style={{ marginLeft: "auto", marginRight: "16px" }}>
            <button
              className="clear-basket-btn  ms-2"
              onClick={handleOpenSettings}>
              <svg
                className="me-1"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="15"
                viewBox="0 0 16 15"
                fill="none">
                <path
                  d="M10.5205 7.5C10.5205 8.89202 9.39202 10.0205 8 10.0205C6.60798 10.0205 5.47953 8.89202 5.47953 7.5C5.47953 6.10798 6.60798 4.97953 8 4.97953C9.39202 4.97953 10.5205 6.10798 10.5205 7.5Z"
                  stroke={theme === "light" ? "#383FEC" : "#74B9F9"}
                />
                <path
                  d="M6.91899 1.11246C7.40833 0.295847 8.59167 0.295848 9.08101 1.11246L9.52572 1.85459C9.8111 2.33083 10.3745 2.5642 10.9131 2.42925L11.7523 2.21894C12.6757 1.98752 13.5125 2.82427 13.2811 3.74771L13.0708 4.58694C12.9358 5.12548 13.1692 5.6889 13.6454 5.97428L14.3875 6.41899C15.2042 6.90833 15.2042 8.09167 14.3875 8.58101L13.6454 9.02572C13.1692 9.3111 12.9358 9.87452 13.0708 10.4131L13.2811 11.2523C13.5125 12.1757 12.6757 13.0125 11.7523 12.7811L10.9131 12.5708C10.3745 12.4358 9.8111 12.6692 9.52572 13.1454L9.08101 13.8875C8.59167 14.7042 7.40833 14.7042 6.91899 13.8875L6.47428 13.1454C6.1889 12.6692 5.62548 12.4358 5.08694 12.5708L4.24771 12.7811C3.32426 13.0125 2.48752 12.1757 2.71894 11.2523L2.92925 10.4131C3.0642 9.87452 2.83083 9.3111 2.35459 9.02572L1.61246 8.58101C0.795847 8.09167 0.795848 6.90833 1.61246 6.41899L2.35459 5.97428C2.83083 5.6889 3.0642 5.12548 2.92925 4.58694L2.71894 3.74771C2.48752 2.82426 3.32427 1.98752 4.24771 2.21894L5.08694 2.42925C5.62548 2.5642 6.1889 2.33083 6.47428 1.85459L6.91899 1.11246Z"
                  stroke={theme === "light" ? "#383FEC" : "#74B9F9"}
                />
              </svg>
              Configure
            </button>
            <button
              className={`open-basket position-relative ms-1 ${basketData.length === 0 ? "disabled" : ""}`}
              onClick={handleOpenBasket}
              style={{
                backgroundColor: basketData.length === 0 ? "#989898" : "",
              }}
              disabled={basketData.length === 0}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                className="me-1">
                <g clipPath="url(#clip0_375_752)">
                  <path
                    d="M8.78566 6.78402C8.78566 6.45052 9.05776 6.17977 9.39293 6.17977C9.7279 6.17977 10 6.45052 10 6.78402V8.5008C10 8.91289 9.83045 9.28842 9.55776 9.55996C9.28507 9.83129 8.90766 10 8.49332 10H1.50668C1.09234 10 0.714931 9.83129 0.44224 9.55996C0.169548 9.28842 0 8.91289 0 8.5008V1.4992C0 1.08691 0.169548 0.711381 0.44224 0.440044C0.714931 0.168706 1.09234 0 1.50668 0H3.21552C3.55069 0 3.82259 0.270751 3.82259 0.604058C3.82259 0.937561 3.55069 1.20831 3.21552 1.20831H1.50668C1.42672 1.20831 1.35305 1.24135 1.3002 1.29374C1.24754 1.34633 1.21434 1.41963 1.21434 1.4992V8.5008C1.21434 8.58017 1.24754 8.65367 1.3002 8.70606C1.35305 8.75845 1.42672 8.79169 1.50668 8.79169H8.49332C8.57328 8.79169 8.64695 8.75845 8.6998 8.70606C8.75246 8.65367 8.78566 8.58017 8.78566 8.5008V6.78402ZM8.78566 2.02272L6.05403 4.77538C5.82004 5.01232 5.43615 5.01544 5.19823 4.78262C4.96031 4.54979 4.95697 4.16781 5.19096 3.93107L7.89332 1.20831H6.08448C5.74931 1.20831 5.47741 0.937561 5.47741 0.604058C5.47741 0.270751 5.74931 0 6.08448 0H9.39293C9.7279 0 10 0.270751 10 0.604058V3.83039C10 4.1639 9.7279 4.43445 9.39293 4.43445C9.05776 4.43445 8.78566 4.1639 8.78566 3.83039V2.02272Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_375_752">
                    <rect width="10" height="10" fill="white" />
                  </clipPath>
                </defs>
              </svg>{" "}
              Open
              <span className="basket-badge position-absolute top-0 start-100 translate-middle rounded-circle text-black">
                {basketData.length}
              </span>
            </button>
          </div>
        </div>
      </div>
      {/* Conditionally render the BasketMobile component */}
      {isBasketOpen && (
        <BasketMobile isOpen={isBasketOpen} onClose={handleCloseBasket} />
      )}
      {/* Conditionally render the Settings component */}
      {isSettingsOpen && (
        <SettingsMobile
          isOpen={isSettingsOpen}
          onClose={handleCloseSettings}
          showCalls={showCalls}
          setShowCalls={setShowCalls}
          showPuts={showPuts}
          setShowPuts={setShowPuts}
          showDelta={showDelta}
          setShowDelta={setShowDelta}
          showTheta={showTheta}
          setShowTheta={setShowTheta}
          showGamma={showGamma}
          setShowGamma={setShowGamma}
          showVega={showVega}
          setShowVega={setShowVega}
          showOIChng={showOIChng}
          setShowOIChng={setShowOIChng}
          showOIBuilddup={showOIBuilddup}
          setShowOIBuilddup={setShowOIBuilddup}
          initialSliderValue={numberOfRows}
          onSliderChange={handleSliderChange}
          sliderLength={sliderLength}
        />
      )}

      <div
        className="option-chain-table-container mt-3"
        style={{ marginBottom: "15px" }}>
        {optionData ? (
          <OptionChainTableMobile
            optionData={optionData}
            options={optionData.options}
            showCalls={showCalls}
            showPuts={showPuts}
            calls={calls}
            puts={puts}
            parentCallback2={handleCallback2}
            clickEnable={true}
            symbol={selectedSymbol}
            showDelta={showDelta}
            showGamma={showGamma}
            showVega={showVega}
            showTheta={showTheta}
            showOIChng={showOIChng}
            showOIBuilddup={showOIBuilddup}
            selectedDate={activeDate}
            setObjectData={setObjectData}
            objectData={objectData}
          />
        ) : (
          <div>Loading data...</div>
        )}
      </div>
    </div>
  );
};

export default OptionChainMobile;
