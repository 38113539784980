import React, { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/header/Header";
import StrategyCreator from "./screens/StrategyCreator/StrategyCreator";
import Positions from "./screens/Positions/Positions";
import Orders from "./screens/Orders/Orders";
import OptionChain from "./screens/OptionChain/OptionChain";
import Watchlist from "./screens/Watchlist";
import VirtualPositions from "./screens/virtualScreens/Positions/VirtualPositions";
import VirtualOrders from "./screens/virtualScreens/Orders/VirtualOrders";
import RiskDisclosure from "./modals/RiskDisclosure";
import { ButtonContext } from "./context/ButtonsContext";
import Screener from "./screens/Screener";
import ScreenersDetail from "./screens/ScreenersDetail";
import StockDetails from "./screens/StockDetails/StockDetails";
import Discover from "./screens/Discover/Discover";
import FiiDii from "./screens/FiiDii/FiiDIi";
import Calculator from "./components/Calculators";
import ChatbotEmbed from "./components/ChatbotEmbed";
import MobileAppAnnouncement from "./components/MobileAppAnnouncement";

function App() {
  const { isVirtual } = useContext(ButtonContext);

  const PositionsComponent = isVirtual ? VirtualPositions : Positions;
  const OrdersComponent = isVirtual ? VirtualOrders : Orders;

  useEffect(() => {
    const screenWidth = window.screen.width;
    if (screenWidth > 1300 && screenWidth < 1396) {
      document.documentElement.style.zoom = "90%";
    }
    return () => {
      document.documentElement.style.zoom = "100%"; // Reset zoom when component unmounts
    };
  }, []);

  return (
    <>
      <ToastContainer />
      <Header />
      <RiskDisclosure />
      {/* <MobileAppAnnouncement /> */}
      <div className="grid-container">
        {/* <ChatbotEmbed /> */}
        <Routes>
          <Route path="/" element={<StrategyCreator />} />
          <Route path="/optionchain" element={<OptionChain />} />
          <Route path="/positions" element={<PositionsComponent />} />
          <Route path="/orders" element={<OrdersComponent />} />
          <Route path="/screeners/:type" element={<Screener />} />
          <Route path="/screenerDetails" element={<ScreenersDetail />} />
          <Route path="/StockDetails" element={<StockDetails />} />
          <Route path="/watchlist" element={<Watchlist />} />
          <Route path="/discover" element={<Discover />} />
          <Route path="/fiidii" element={<FiiDii />} />
          <Route
            path="/fibbanoci"
            element={<Calculator title={"Fibonacci calculator"} />}
          />
          <Route
            path="/pivotpoints"
            element={<Calculator title={"Pivot points calculator"} />}
          />
          <Route
            path="/Optionspricing"
            element={<Calculator title={"OptionsPrice"} />}
          />
        </Routes>
      </div>
    </>
  );
}

export default App;
