import React from "react";
import FiiTableComponent from "./FiiTableComponent";

const FiiDiiMobile = () => {
  return (
    <div className="fiidii-mobile">
      <FiiTableComponent />
    </div>
  );
};

export default FiiDiiMobile;
